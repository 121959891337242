import Joi from "@hapi/joi"
import { addMonths, addMinutes, isTomorrow } from "date-fns"
import { availabilityType } from "utils/availabilityType"

const availabilityValidation = (data) =>
  Joi.object({
    availabilityDate: Joi.date()
      .greater(new Date())
      .less(addMonths(new Date(), 3))
      .required()
      .error((errors) => {
        const [error] = errors || []
        switch (error.code) {
          case "date.greater":
            error.message = "Datum kan inte vara före imorgon"
            break
          case "date.less":
            error.message =
              "Datum kan inte vara mer än tre månader framåt i tiden"
            break
          default:
            error.message = "Vänligen ange ett giltigt datum"
            break
        }
        return errors
      }),
    availabilityStartTime: Joi.date()
      .when(
        Joi.ref(".", {
          adjust: (value) => {
            if (value) {
              const regexForTimeWithZeroOrThirtyMinutesOnly =
                /^(2[0-3]|[01]?[0-9]):((0|3)?(0))$/
              const startTime = `${value.getHours()}:${value.getMinutes()}`
              const match = startTime.match(
                regexForTimeWithZeroOrThirtyMinutesOnly
              )
              return !!match
            }
            return false
          },
        }),
        {
          is: false,
          then: Joi.date().invalid,
        }
      )
      .min(
        Joi.ref("availabilityDate", {
          adjust: (value) => {
            if (value && isTomorrow(value)) return new Date()
            // setHours(0, -1, 0) is to enable putting midnight
            return new Date().setHours(0, -1, 0)
          },
        })
      )
      .required()
      .error((errors) => {
        const [error] = errors || []
        switch (error.code) {
          case "any.custom":
            error.message = "Starttiden måste vara :00 eller :30"
            break
          case "date.min":
            error.message = "Starttiden måste vara minst 24h i framtiden"
            break
          default:
            error.message = "Var vänlig ange en giltig starttid"
            break
        }
        return errors
      }),
    availabilityEndTime: Joi.date()
      .when(
        Joi.ref(".", {
          adjust: (value) => {
            if (value) {
              const regexForTimeWithZeroOrThirtyMinutesOnly =
                /^(2[0-3]|[01]?[0-9]):((0|3)?(0))$/
              const endTime = `${value.getHours()}:${value.getMinutes()}`
              const match = endTime.match(
                regexForTimeWithZeroOrThirtyMinutesOnly
              )
              return !!match
            }
            return false
          },
        }),
        {
          is: false,
          then: Joi.date().invalid,
        }
      )
      .greater(
        Joi.ref("availabilityStartTime", {
          adjust: (value) => addMinutes(value, 1),
        })
      )
      .required()
      .error((errors) => {
        const [error] = errors || []
        switch (error.code) {
          case "any.custom":
            error.message = "Sluttiden måste vara :00 eller :30"
            break
          case "date.greater":
            error.message = "Sluttiden måste vara efter starttiden"
            break
          default:
            error.message = "Var vänlig ange en giltig sluttid"
            break
        }
        return errors
      }),
    availabilityType: Joi.number()
      .valid(...Object.values(availabilityType))
      .error((errors) =>
        errors.map((err) => {
          err.message = "Ange en giltig upprepningstyp"
          return err
        })
      ),
  }).validate(data, {
    abortEarly: true,
  })

export default availabilityValidation
