import { useState, useCallback, useEffect } from "react"
import { connect } from "react-redux"
import { fetchCalendarEvents } from "redux/actions"
import {
  Calendar as BigCalendar,
  Views,
  dateFnsLocalizer,
} from "react-big-calendar"
import "react-big-calendar/lib/css/react-big-calendar.css"
import format from "date-fns/format"
import parse from "date-fns/parse"
import startOfWeek from "date-fns/startOfWeek"
import getDay from "date-fns/getDay"
import { sv } from "date-fns/locale"
import isBefore from "date-fns/isBefore"
import isSameDay from "date-fns/isSameDay"
import { Box, Hidden, Typography } from "@mui/material"
import TouchButton from "components/TouchButton"
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import CalendarToolbar from "./components/CalendarToolbar"
import AvailabilityDialog from "./components/AvailabilityDialog"
import MeetingDialog from "./components/MeetingDialog"
import MeetingRoomDialog from "./components/MeetingRoomDialog"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(3),
  },
}))

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales: { sv },
})
const calendarViews = Object.keys(Views).map((view) => Views[view])

const Calendar = ({ dispatch, userId, calendarEvents }) => {
  const classes = useStyles()
  const bookings = calendarEvents?.bookings || []
  const availabilities = calendarEvents?.available || []
  const [calendarView, setCalendarView] = useState(Views.WEEK)
  const [openAvailabilityDialog, setOpenAvailabilityDialog] = useState(false)
  const [openMeetingDialog, setOpenMeetingDialog] = useState(false)
  const [openMeetingRoomDialog, setOpenMeetingRoomDialog] = useState(false)
  const [availability, setAvailability] = useState(null)
  const [meeting, setMeeting] = useState(null)
  const [meetingRoomUrl, setMeetingRoomUrl] = useState(null)

  useEffect(() => {
    dispatch(fetchCalendarEvents(userId))
  }, [dispatch])

  const onViewChange = useCallback(
    (newView) => setCalendarView(newView),
    [setCalendarView]
  )

  const handleOpenAvailabilityDialog = () => {
    setAvailability(null)
    setOpenAvailabilityDialog(true)
  }

  const handleJoinMeeting = (url) => {
    setMeetingRoomUrl(url)
    setOpenMeetingDialog(false)
    setOpenMeetingRoomDialog(true)
  }

  const handleSelectEvent = useCallback(
    (event) => {
      // meeting event has resource property, availability event doesn't
      if (event.resource) {
        setMeeting(event)
        setOpenMeetingDialog(true)
      } else {
        if (isBefore(event.end, new Date()) || isSameDay(event.end, new Date()))
          return
        setAvailability(event)
        setOpenAvailabilityDialog(true)
      }
    },
    [
      setAvailability,
      setOpenAvailabilityDialog,
      setMeeting,
      setOpenMeetingDialog,
    ]
  )

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <Box mx={2} mt={2}>
          <TouchButton to="/">
            <ArrowBackIcon />
            <Box ml={1}>
              <Typography color="primary">Tillbaka till chatten</Typography>
            </Box>
          </TouchButton>
        </Box>
      </Hidden>
      <Box m={2}>
        <BigCalendar
          culture="sv"
          localizer={localizer}
          views={calendarViews}
          defaultView={calendarView}
          defaultDate={new Date()}
          step={30}
          messages={{
            date: "Datum",
            time: "Tid",
            event: "Möte",
            noEventsInRange: "Det finns inga möten i det här intervallet.",
            showMore: (total) => `+${total} mer`,
          }}
          onView={onViewChange}
          selectable
          components={{
            toolbar: (props) =>
              CalendarToolbar({ handleOpenAvailabilityDialog, ...props }),
          }}
          backgroundEvents={availabilities}
          events={bookings}
          onSelectEvent={handleSelectEvent}
        />
      </Box>
      <AvailabilityDialog
        openDialog={openAvailabilityDialog}
        onClose={() => setOpenAvailabilityDialog(false)}
        userId={userId}
        availability={availability}
      />
      <MeetingDialog
        openDialog={openMeetingDialog}
        onClose={() => setOpenMeetingDialog(false)}
        meeting={meeting}
        onJoinMeeting={handleJoinMeeting}
      />
      <MeetingRoomDialog
        openDialog={openMeetingRoomDialog}
        onClose={() => setOpenMeetingRoomDialog(false)}
        meetingRoomUrl={meetingRoomUrl}
      />
    </div>
  )
}

const mapStateToProps = ({ user, calendarEvents }) => {
  const { userId } = user

  return { userId, calendarEvents }
}

export default connect(mapStateToProps)(Calendar)
