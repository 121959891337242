/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { availabilityConfirmationDialogType } from "utils/availabilityConfirmationDialogType"
import { availabilityUpdateType as updateType } from "utils/availabilityUpdateType"

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    opacity: 0.8,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      opacity: 1,
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  radio: {
    [theme.breakpoints.down("sm")]: {
      "&:first-of-type": {
        marginBottom: theme.spacing(2),
      },
      marginRight: 0,
    },
  },
}))

const AvailabilityConfirmationDialog = ({
  onClose,
  openDialog,
  onDeleteAvailability,
  onUpdateAvailability,
  dialogType,
  isRecurringAvailability,
}) => {
  const classes = useStyles()
  const [availabilityUpdateType, setAvailabilityUpdateType] = useState(
    updateType.SINGLE
  )
  const isDeleteDialogType =
    dialogType === availabilityConfirmationDialogType.DELETE
  const nonRecurringAvailabilityUpdateType = updateType.ALL

  useEffect(() => {
    setAvailabilityUpdateType(updateType.SINGLE)
  }, [openDialog])

  return (
    <Dialog open={openDialog} onClose={onClose}>
      <DialogTitle>
        {isDeleteDialogType
          ? isRecurringAvailability
            ? "Radera återkommande tillgänglighet"
            : "Radera tillgänglighet"
          : "Uppdatera återkommande tillgänglighet"}
      </DialogTitle>
      <DialogContent>
        {isRecurringAvailability ? (
          <RadioGroup
            defaultValue={updateType.SINGLE}
            name="recurring-availability-radio-group"
            value={availabilityUpdateType}
            onChange={(event) => setAvailabilityUpdateType(event.target.value)}
          >
            <FormControlLabel
              value={updateType.SINGLE}
              control={<Radio />}
              label={`${
                isDeleteDialogType ? "Radera" : "Uppdatera"
              } tillgänglighet`}
              className={classes.radio}
            />
            {isDeleteDialogType && (
              <FormControlLabel
                value={updateType.FOLLOWING}
                control={<Radio />}
                label="Radera återkommande tillgänglighet framåt i tiden"
                className={classes.radio}
              />
            )}
            <FormControlLabel
              value={updateType.ALL}
              control={<Radio />}
              label={`${
                isDeleteDialogType ? "Radera" : "Uppdatera"
              } hela återkommande tillgängligheten`}
              className={classes.radio}
            />
          </RadioGroup>
        ) : (
          "Denna tillgänglighet kommer att raderas. Är du säker?"
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className={classes.closeButton}
          color="default"
        >
          Avbryt
        </Button>
        <Button
          onClick={() =>
            isDeleteDialogType
              ? onDeleteAvailability(
                  isRecurringAvailability
                    ? availabilityUpdateType
                    : nonRecurringAvailabilityUpdateType
                )
              : onUpdateAvailability(availabilityUpdateType)
          }
          autoFocus
          className={classes.confirmButton}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AvailabilityConfirmationDialog
