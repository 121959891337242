import React, { Fragment, useState } from "react"

import {
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TableSortLabel,
  Tooltip,
  Box,
  Collapse,
  Hidden,
  IconButton,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import {
  InfoOutlined as InfoOutlinedIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material"

const DEFAULT_PAGE = 0
const PER_PAGE_VALUES = [20, 40, 60]
const [DEFAULT_PER_PAGE] = PER_PAGE_VALUES

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    overflow: "auto",
  },
  tableColumn: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  tableCell: {
    [theme.breakpoints.down("sm")]: {
      padding: "10px !important",
    },
  },
  sortIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  sortableWrapper: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "initial",
    },
  },
  emptyText: {
    color: theme.palette.black,
  },
  start: {
    display: "flex",
    alignItems: "center",
  },
  infoIcon: {
    fontSize: "1rem !important",
    color: `${theme.palette.primary.main} !important`,
    marginLeft: "5px",
  },
}))

export const Table = ({
  rowIdKey = "id",
  columns = [],
  data = [],
  order = "desc",
  orderBy = "createdAt",
  usePagination = false,
  totalItemsCount = 0,
  rowsPerPage = DEFAULT_PER_PAGE,
  page = DEFAULT_PAGE,
  emptyText = "",
  onSortChange = () => null,
  onPaginationChange = () => null,
  onRowClick = () => null,
}) => {
  const classes = useStyles()
  const [collapsedIds, setCollapsedIds] = useState([])

  const handleChangePage = (event, newPage) => {
    onPaginationChange({ page: newPage, perPage: rowsPerPage })
  }

  const handleChangeRowsPerPage = (event) => {
    onPaginationChange({ page: 0, perPage: +event.target.value })
  }

  const renderCellDefaultData = (label) => (
    <Typography variant="body1" className={classes.tableColumn}>
      {label}
    </Typography>
  )

  const renderCellDataWithTooltip = (tooltipTitle, label) => (
    <Box className={classes.start}>
      {renderCellDefaultData(label)}
      <Tooltip title={tooltipTitle} placement="right-end">
        <InfoOutlinedIcon className={classes.infoIcon} />
      </Tooltip>
    </Box>
  )

  const renderHeaderCellData = ({ id, sortable, label, tooltipTitle }) => {
    if (sortable) {
      return (
        <TableSortLabel
          active={orderBy === id}
          direction={orderBy === id ? order : "asc"}
          onClick={() => onSortChange(id)}
          classes={{ icon: classes.sortIcon, root: classes.sortableWrapper }}
        >
          {tooltipTitle
            ? renderCellDataWithTooltip(tooltipTitle, label)
            : renderCellDefaultData(label)}
        </TableSortLabel>
      )
    }
    if (tooltipTitle) {
      return renderCellDataWithTooltip(tooltipTitle, label)
    }
    return renderCellDefaultData(label)
  }

  const renderHeadCell = (column, style) => (
    <TableCell
      key={`head_cell_${column.id}`}
      align={column.align}
      sortDirection={column.sortable && orderBy === column.id ? order : false}
      className={classes.tableCell}
      style={{ minWidth: column.minWidth, ...style }}
    >
      {renderHeaderCellData(column)}
    </TableCell>
  )

  const renderBodyCell = (row, column, rowIndex, style = {}) => {
    const value = row[column.id]

    return (
      <TableCell
        key={`body_cell_${column.id}`}
        align={column.align}
        style={style}
      >
        {column.formatBodyCell
          ? column.formatBodyCell({
              value,
              rowData: row,
              index: rowIndex,
              page,
              perPage: rowsPerPage,
            })
          : value}
      </TableCell>
    )
  }

  const renderBodyRow = (row, rowIndex) => (
    <TableRow
      role="checkbox"
      tabIndex={-1}
      key={`body_row_${row[rowIdKey]}`}
      onClick={() => {
        if (onRowClick) {
          onRowClick(row)
        }
      }}
    >
      {columns.map((column) => renderBodyCell(row, column, rowIndex))}
    </TableRow>
  )

  const getMobileHeaderColumns = () =>
    columns.filter((column) => column.mobileTitle)

  const getMobileSubHeaderColumns = () =>
    columns.filter((column) => !column.mobileTitle)

  const onMobileExpand = (rowKeyId) => {
    const exists = collapsedIds.find((id) => id === rowKeyId)
    const newIds = exists
      ? [...collapsedIds.filter((id) => rowKeyId !== id)]
      : [...collapsedIds, rowKeyId]

    setCollapsedIds(newIds)
  }

  const renderMobileBodyRow = (row, rowIndex) => {
    const mobileHeaderColumns = getMobileHeaderColumns()
    const cellStyle = { border: 0, width: "45%", padding: "10px" }
    const lastCellStyle = { border: 0, width: "10%", padding: 0 }

    return (
      <Fragment key={`mobile_body_row_${row[rowIdKey]}`}>
        <TableRow
          role="checkbox"
          tabIndex={-1}
          onClick={() => {
            if (onRowClick) {
              onRowClick(row)
            }
          }}
        >
          {mobileHeaderColumns.map((column) =>
            renderBodyCell(row, column, rowIndex, cellStyle)
          )}
          <TableCell align="right" style={lastCellStyle}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => onMobileExpand(row[rowIdKey])}
            >
              {collapsedIds.includes(row[rowIdKey]) ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={3}>
            <Collapse
              in={collapsedIds.includes(row[rowIdKey])}
              timeout="auto"
              unmountOnExit
            >
              <MaterialTable size="small" aria-label="purchases">
                <TableBody>
                  {getMobileSubHeaderColumns().map((column) => (
                    <TableRow key={`table_row_${column.id}`}>
                      {renderHeadCell(column, {
                        ...cellStyle,
                        textAlign: "left",
                      })}
                      {renderBodyCell(row, column, rowIndex, cellStyle)}
                      <TableCell align="right" style={lastCellStyle} />
                    </TableRow>
                  ))}
                </TableBody>
              </MaterialTable>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }

  if (!data.length) {
    return (
      <Typography variant="body2" className={classes.emptyText}>
        {emptyText}
      </Typography>
    )
  }

  return (
    <div className={classes.wrapper}>
      <Hidden smDown>
        <TableContainer>
          <MaterialTable>
            <TableHead>
              <TableRow>
                {columns.map((column) => renderHeadCell(column))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length
                ? data.map((row, index) => renderBodyRow(row, index, columns))
                : null}
            </TableBody>
          </MaterialTable>
        </TableContainer>
      </Hidden>
      <Hidden smUp>
        <MaterialTable>
          <TableHead>
            <TableRow>
              {getMobileHeaderColumns().map((column) => renderHeadCell(column))}
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length
              ? data.map((row, index) => renderMobileBodyRow(row, index))
              : null}
          </TableBody>
        </MaterialTable>
      </Hidden>
      {usePagination ? (
        <TablePagination
          rowsPerPageOptions={PER_PAGE_VALUES}
          component="div"
          count={totalItemsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </div>
  )
}
