import React from "react"
import { connect } from "react-redux"
import { Snackbar, Alert as MuiAlert } from "@mui/material/"

const Alert = ({ alert, dispatch }) => {
  if (alert) {
    const { type, message, show, hide } = alert

    return (
      <Snackbar
        open={show}
        onClose={() => dispatch(hide())}
        autoHideDuration={7000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={type}
          color={type}
          onClose={() => dispatch(hide())}
        >
          {message}
        </MuiAlert>
      </Snackbar>
    )
  }
  return null
}

function mapStateToProps({ alert }) {
  return { alert }
}

export default connect(mapStateToProps)(Alert)
