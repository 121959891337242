import Api from "api"

export const getKaddioFormLink = (
  kaddioCaregiverUserId,
  kaddioPatientUserId,
  kaddioFormId
) =>
  Api.get(
    `/users/caregiver/kaddio/${kaddioCaregiverUserId}/form/${kaddioPatientUserId}/${kaddioFormId}/link`
  )
