import Cookie from "js-cookie"
import { fetchUser, setInitialLoading } from "redux/actions"

export const getCaregiverFromCookie = (dispatch) => {
  const caregiverCookie = Cookie.get("caregiver")
  const caregiver = caregiverCookie ? JSON.parse(caregiverCookie) : null
  if (caregiver) {
    dispatch(fetchUser(caregiver))
    return caregiver
  }
  dispatch(setInitialLoading(false))
  return null
}
