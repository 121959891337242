import {
  Dialog,
  Card,
  DialogActions,
  Button,
  useMediaQuery,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useTheme } from "@emotion/react"

const useStyles = makeStyles(() => ({
  meetingRoomDialog: {
    "& .MuiDialog-paperWidthLg.MuiDialog-paperFullWidth": {
      height: "100%",
    },
    "& .MuiCard-root": {
      height: "95%",
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
}))

const MeetingRoomDialog = ({ onClose, openDialog, meetingRoomUrl }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isWidthSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Dialog
      fullWidth={!isWidthSmDown}
      maxWidth="lg"
      fullScreen={isWidthSmDown}
      open={openDialog}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          onClose()
        }
      }}
      className={classes.meetingRoomDialog}
    >
      <Card>
        <iframe
          title="meetingRoomVideo"
          src={meetingRoomUrl}
          allow="camera;microphone"
          width="100%"
          height="100%"
        />
      </Card>
      <DialogActions>
        <Button
          size="small"
          color="default"
          onClick={() => onClose()}
          className={classes.closeButton}
        >
          Stäng
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MeetingRoomDialog
