import React, { useEffect, useState, useRef } from "react"
import { useLocalStorage } from "utils/useLocalStorage"
import { getDifferenceBetweenTwoDates } from "utils/date"
import { connect } from "react-redux"
import {
  Box,
  Typography,
  IconButton,
  Hidden,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import AnonymousModeWrapper from "components/AnonymousModeWrapper"
import isEmpty from "lodash.isempty"
import {
  ArrowBack as ArrowBackIcon,
  ExpandMore as ExpandMoreIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material"
import { translateExpertise } from "utils/translateExpertise"
import { format } from "date-fns"
import { sv } from "date-fns/locale"
import { patientType } from "utils/patientType"
import CopyUserId from "./PatientCopyId"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.lighter_gray,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      borderBottom: 0,
    },
  },
  test: {
    display: "contents",
    "& > div:last-of-type": {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      "& > div:last-of-type": {
        marginBottom: theme.spacing(1),
      },
    },
  },
  question: {
    marginRight: theme.spacing(2),
    "&:last-of-type": {
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
      "&:last-of-type": {
        marginBottom: 0,
      },
    },
  },
  questionText: {
    fontWeight: "bold",
  },
  icon: {
    color: "white",
  },
  accordionTitle: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.white,
    textDecoration: "none",
    alignSelf: "center",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1.876, 0),
      width: "95%",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  title: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.125rem",
      padding: theme.spacing(1, 0, 0, 0),
    },
  },
  subTitle: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
      lineHeight: "1rem",
    },
  },
  accordion: {
    border: "0rem",
    backgroundColor: theme.palette.primary.main,
  },
  accordionDetails: {
    backgroundColor: theme.palette.lighter_gray,
    display: "flex",
    flexDirection: "column",
    maxHeight: "calc((100vh - 355px) / 2)",
    overflow: "auto",
    overflowX: "hidden",
  },
  accordionFooter: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start",
    backgroundColor: theme.palette.gray,
  },
  backButtonWrapper: {
    alignSelf: "center",
    padding: theme.spacing(1.25, 0),
  },
  backButton: {
    color: theme.palette.white,
  },
  arrowUpIcon: {
    cursor: "pointer",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
  },
  sessionEndText: {
    alignSelf: "flex-end",
    padding: theme.spacing(0.5, 0),
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
      padding: theme.spacing(1, 0, 1, 0),
    },
  },
}))

const Skills = (expertise) => {
  const translatedExpertise = expertise.map((exp) => translateExpertise(exp))
  return translatedExpertise.join(", ")
}

const PatientInformation = ({
  information,
  answers,
  patientQuestions,
  onBackClick,
  active,
}) => {
  const [collapsed, setCollapsed] = useLocalStorage("headerCollapsed", true)

  const classes = useStyles(collapsed)
  const { firstName, lastName, socialSecurity, userId } = information
  const [currentSession, setCurrentSession] = useState()
  const topRef = useRef(null)

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView()
    }
  }, [information])

  useEffect(() => {
    if (active && userId) {
      const sessions = active.find((session) => session.userId === userId)
      setCurrentSession(sessions)
    }
  }, [active, userId, information])

  const formatAnswer = (question, answer) => {
    switch (question.type) {
      // currently only the type of help being sought
      case "multipleAnswer":
        return Skills(answer.answer)
      case "singleAnswer":
        return (
          question.answers.find((el) => el.value === answer?.answer[0])?.text ||
          "-"
        )
      case "freeText":
      default:
        return answer?.answer[0] || "-"
    }
  }

  const getAge = (ssn) => {
    const birthYear = +ssn.substring(0, 4)
    const birthMonth = +ssn.substring(4, 6)
    const birthDay = +ssn.substring(6, 8)

    const userBirthdayDate = new Date(birthYear, birthMonth - 1, birthDay)
    const age = getDifferenceBetweenTwoDates(userBirthdayDate)

    return age
  }

  if (isEmpty(answers)) {
    return null
  }

  const toggleInfo = () => {
    setCollapsed(!collapsed)
  }

  const formatPersonalNumber = (personalNumber) =>
    personalNumber.replace(/^(\d{8})(\d{4})$/g, "$1-$2")

  const handleBackClick = (e) => {
    e.stopPropagation()

    onBackClick()
  }

  return (
    <Box>
      <Accordion
        variant="outlined"
        className={classes.accordion}
        expanded={collapsed}
        square
        onChange={toggleInfo}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
        >
          <Hidden smUp>
            <Box className={classes.backButtonWrapper}>
              <IconButton onClick={handleBackClick}>
                <ArrowBackIcon
                  fontSize="large"
                  className={classes.backButton}
                />
              </IconButton>
            </Box>
          </Hidden>
          <Typography
            component="span"
            variant="body1"
            className={classes.accordionTitle}
          >
            <Box className={classes.titleBox}>
              <Typography
                component="span"
                variant="h4"
                className={classes.title}
              >
                <AnonymousModeWrapper>
                  {firstName} {lastName}
                  <span> ({getAge(socialSecurity)} år)</span>
                </AnonymousModeWrapper>
              </Typography>
              <Typography
                component="span"
                variant="h6"
                className={classes.subTitle}
              >
                <AnonymousModeWrapper>
                  {formatPersonalNumber(socialSecurity)}
                  <CopyUserId id={information.userId} />
                </AnonymousModeWrapper>
              </Typography>
            </Box>
            {currentSession && (
              <Box>
                {currentSession?.patientType !== patientType.PAYING ? (
                  <AnonymousModeWrapper>
                    <Typography className={classes.sessionEndText}>
                      [Frikort/Under20/85+]
                    </Typography>
                  </AnonymousModeWrapper>
                ) : (
                  <AnonymousModeWrapper>
                    <Typography className={classes.sessionEndText}>
                      Sessionsslut:{" "}
                      {format(
                        new Date(currentSession.endedAt),
                        "dd MMMM HH:mm",
                        {
                          locale: sv,
                        }
                      )}
                    </Typography>
                  </AnonymousModeWrapper>
                )}
              </Box>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Box ref={topRef} className={classes.root}>
            {patientQuestions.map((pq, index) => {
              // Use first 5 questions
              if (index > 4) return null
              return (
                <Box
                  key={`${pq.id}_${index}`}
                  className={classes.question}
                  mt={index === 0 ? 0 : 1}
                >
                  <Typography className={classes.questionText}>
                    {pq.question}
                  </Typography>
                  <Typography>
                    <AnonymousModeWrapper>
                      {formatAnswer(
                        pq,
                        answers.find(({ questionId }) => questionId === pq.id)
                      )}
                    </AnonymousModeWrapper>
                  </Typography>
                </Box>
              )
            })}
          </Box>
        </AccordionDetails>
        <Box className={classes.accordionFooter} onClick={toggleInfo}>
          <KeyboardArrowUpIcon className={classes.arrowUpIcon} />
        </Box>
      </Accordion>
    </Box>
  )
}

const mapStateToProps = ({ user, chats, patientQuestions, sessions }) => {
  const { userId } = user
  const {
    patient: { information, answers },
  } = chats
  const { active } = sessions

  return {
    userId,
    information,
    answers,
    patientQuestions,
    active,
  }
}

export default connect(mapStateToProps)(PatientInformation)
