import moment from "moment"

export const getDifferenceBetweenTwoDates = (
  olderDate,
  laterDate = moment().startOf("day"),
  inTimeType = "years",
  format = "DD-MM-YYYY"
) => {
  const formattedOlderDate = moment(olderDate, format)
  const formattedLaterDate = moment(laterDate, format)

  return formattedLaterDate.diff(formattedOlderDate, inTimeType)
}
