import Api from "api"
import isEmpty from "lodash.isempty"
import Cookie from "js-cookie"
import moment from "moment"
import { getUser } from "api/getUser"
import { getExpertise } from "api/getExpertise"
import { updateCaregiver } from "api/updateCaregiver"
import { getUserChats } from "api/getUserChats"
import { fileUpload } from "api/fileUpload"
import { updateUser } from "api/updateUser"
import { getChatEntries } from "api/getChatEntries"
import { postChatEntry } from "api/postChatEntry"
import { getPatientAnswers } from "api/getPatientAnswers"
import { getSessionsByCaregiver } from "api/getSessionsByCaregiver"
import { getPatientQuestions } from "api/getPatientQuestions"
import { imageUpload } from "api/imageUpload"
import { chatMessagesSeen } from "api/chatMessagesSeen"
import { getPatients } from "api/getPatients"
import { getKaddioForms } from "api/getKaddioForms"
import { getKaddioUserId } from "api/getKaddioUserId"
import { difference } from "utils/compareObject"
import { connectToSocket } from "api/connectToSocket"
import { patchCaregiverAvailability } from "api/patchCaregiverAvailability"
import { patchCaregiverMaxPatients } from "api/patchCaregiverMaxPatients"
import { getCaregiverCalendarEvents } from "api/getCaregiverCalendarEvents"
import { entryType } from "utils/entryType"
import { sessionType } from "utils/sessionType"
import { defaultPagination } from "utils"
import { availabilityUpdateType as updateType } from "utils/availabilityUpdateType"
import { getDateConsideringDaylightSavingTime } from "utils/getDateConsideringDaylightSavingTime"
import config from "config"

import {
  SET_USER,
  PUT_USER,
  SET_CAREGIVER,
  PUT_CAREGIVER,
  LOGOUT_USER,
  SET_INITIAL_LOADING,
  SET_TOKEN,
  SET_EXPERTISE,
  SET_CHATS,
  SET_PATIENTS,
  POST_MESSAGE,
  SET_PATIENT_QUESTIONS,
  ALERT_SHOW,
  ALERT_HIDE,
  SET_INITIAL_MESSAGE,
  SET_USER_AVATAR,
  SET_JOURNALIZED,
  SET_SESSIONS,
  SET_CHAT_MESSAGES_REQUEST,
  SET_CHAT_MESSAGES_SUCCESS,
  SET_CHAT_MESSAGES_ERROR,
  SET_CHAT_MESSAGES_PAGINATION,
  SET_KADDIO_FORMS,
  SET_KADDIO_CAREGIVER_USER_ID,
  SET_KADDIO_PATIENT_USER_ID,
  SET_CALENDAR_EVENTS,
} from "redux/actionTypes"

export const showAlert = (type, message = "") => {
  const alertTypes = (alertType) => {
    switch (alertType) {
      case "success":
      case "error":
      case "warning":
      case "info":
        return alertType
      default:
        return "success"
    }
  }

  function hideAlert() {
    return {
      type: ALERT_HIDE,
      payload: {
        show: false,
      },
    }
  }

  return {
    type: ALERT_SHOW,
    payload: {
      type: alertTypes(type),
      message,
      show: true,
      hide: hideAlert,
    },
  }
}

const setUser = (data) => (dispatch) => {
  dispatch({
    type: PUT_USER,
    payload: data,
  })
}

const setCaregiver = (data) => (dispatch) => {
  dispatch({
    type: PUT_CAREGIVER,
    payload: data,
  })
}

const convertDatesConsideringDaylightSavingTime = (dates) =>
  dates.map(({ start, end, ...rest }) => ({
    ...rest,
    start: getDateConsideringDaylightSavingTime(start),
    end: getDateConsideringDaylightSavingTime(end),
  }))

export const updateForm = (data) => (dispatch, getState) => {
  const { user, caregiver } = getState()
  const { userId } = user
  const { email, phoneNumber, activeInMatchmaking, maxPatients, ...form } = data
  const userData = { email, phoneNumber }
  const caregiverData = { ...form }

  const userDiff = difference(userData, user)
  const caregiverDiff = difference(caregiverData, caregiver)
  const availabilityDiff = difference(
    { activeInMatchmaking },
    { activeInMatchmaking: user.activeInMatchmaking }
  )
  const maxPatientsDiff = difference(
    { maxPatients },
    { maxPatients: user.maxPatients }
  )

  const formMap = [
    {
      query: () => updateUser(userId, userData),
      action: setUser(userData),
      diff: userDiff,
    },
    {
      query: () => updateCaregiver(userId, caregiverData),
      action: setCaregiver(caregiverData),
      diff: caregiverDiff,
    },
    {
      query: () =>
        patchCaregiverAvailability(userId, { available: activeInMatchmaking }),
      action: setUser(availabilityDiff),
      diff: availabilityDiff,
    },
    {
      query: () => patchCaregiverMaxPatients(userId, { maxPatients }),
      action: setUser(maxPatientsDiff),
      diff: maxPatientsDiff,
    },
  ]

  const [queries, actions] = formMap.reduce(
    (prev, current) => {
      const [prevQueries, prevActions] = prev

      if (!isEmpty(current?.diff)) {
        return [
          [...prevQueries, current?.query()],
          [...prevActions, current?.action],
        ]
      }

      return prev
    },
    [[], []]
  )

  if (!isEmpty(queries)) {
    Promise.allSettled(queries).then((responses) => {
      const fulfilledResponses = responses.filter(({ status }, index) => {
        if (status === "fulfilled") {
          return true
        }

        // Remove rejected actions
        actions.splice(index, 1)
        return false
      })

      if (isEmpty(fulfilledResponses)) {
        dispatch(showAlert("error", "Något gick fel, misslyckades att spara"))
        return null
      }

      actions.forEach((action) => {
        dispatch(action)
      })

      const shouldShowWarning = responses.some(
        ({ status }) => status === "rejected"
      )

      if (shouldShowWarning) {
        dispatch(
          showAlert(
            "warning",
            "Något gick fel, all information blev inte sparad"
          )
        )
      } else {
        dispatch(showAlert("success", "Profil sparad"))
      }
    })
  }
}
export const setInitialLoading = (loadingState) => ({
  type: SET_INITIAL_LOADING,
  payload: loadingState,
})

export const fetchExpertise = () => (dispatch) => {
  getExpertise().then(({ data }) => {
    dispatch({
      type: SET_EXPERTISE,
      payload: data,
    })
  })
}

export const fetchUser = (data) => (dispatch) => {
  const { token, id } = data
  Api.defaults.headers.common.Authorization = `Bearer ${token}`
  dispatch({
    type: SET_TOKEN,
    payload: token,
  })
  Promise.all([getUser(id), connectToSocket()])
    .then((response) => {
      const [{ data: user }] = response
      const { shortDesc, longDesc, expertise, ...rest } = user
      const userData = { ...rest }
      const caregiverData = { shortDesc, longDesc, expertise }

      dispatch({
        type: SET_CAREGIVER,
        payload: caregiverData,
      })
      dispatch({
        type: SET_USER,
        payload: userData,
      })
    })
    .catch(() => {
      // when fail to fetch user redirect back to login screen
      dispatch(setInitialLoading(false))
    })
}

export const fetchUserChats = (userId, incMessageFetch) => (dispatch) => {
  dispatch({
    type: SET_CHATS,
    payload: {
      loading: true,
      error: false,
    },
  })

  return Promise.all([
    getUserChats(userId),
    getPatients(userId),
    getPatientQuestions(),
    getSessionsByCaregiver(userId),
  ])
    .then((response) => {
      const [
        { data: instances },
        { data: patients },
        { data: questions },
        { data: sessions },
      ] = response

      const chatInstancesWithSessions = instances.filter(({ patientId }) =>
        // eslint-disable-next-line no-unsafe-optional-chaining
        [...sessions?.active, ...sessions?.past].find(
          (session) =>
            session.userId === patientId &&
            session.sessionType === sessionType.CHAT
        )
      )

      if (isEmpty(chatInstancesWithSessions)) {
        dispatch({
          type: SET_CHATS,
          payload: {
            loading: false,
            error: false,
            instances: [],
          },
        })

        if (incMessageFetch) return Promise.reject(new Error("no instances"))
      } else {
        dispatch({
          type: SET_SESSIONS,
          payload: sessions,
        })
        dispatch({
          type: SET_CHATS,
          payload: {
            loading: false,
            error: false,
            instances: chatInstancesWithSessions,
          },
        })
        dispatch({
          type: SET_PATIENTS,
          payload: patients,
        })
        dispatch({
          type: SET_PATIENT_QUESTIONS,
          payload: questions,
        })

        if (incMessageFetch) return Promise.resolve("fetchUserChats success")
      }
    })
    .catch((error) => {
      dispatch({
        type: SET_CHATS,
        payload: {
          loading: false,
          error: true,
          instances: [],
        },
      })
      if (incMessageFetch) return Promise.reject(new Error(error))
    })
}

export const fetchChatEntries = (chatId, patientId) => (dispatch, getState) => {
  const {
    chats: { patient },
    user: { userId },
  } = getState()
  Promise.all([getChatEntries(chatId), getPatientAnswers(patientId, userId)])
    .then((response) => {
      const [
        {
          data: { data: chatEntries, totalCount },
        },
        { data: patientAnswers },
      ] = response

      const chatEntriesCopy = [...chatEntries]

      const sortedMessages = chatEntriesCopy.sort((a, b) =>
        moment(a.createdAt).diff(moment(b.createdAt))
      )

      dispatch({
        type: SET_CHATS,
        payload: {
          loading: false,
          error: false,
          messages: sortedMessages,
          messagesCount: totalCount,
          patient: {
            ...patient,
            answers: patientAnswers,
          },
          switchChatLoading: false,
        },
      })

      chatMessagesSeen(chatId, userId)
    })
    .catch((error) => {
      dispatch({
        type: SET_CHATS,
        payload: {
          loading: false,
          error: true,
          messages: [],
          patient: {
            information: null,
            answers: [],
          },
          switchChatLoading: false,
        },
      })
    })
}

export const fetchEntries = (chatId, pagination) => async (dispatch) => {
  try {
    dispatch({ type: SET_CHAT_MESSAGES_REQUEST })
    const { data } = await getChatEntries(chatId, pagination)

    dispatch({
      type: SET_CHAT_MESSAGES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({ type: SET_CHAT_MESSAGES_ERROR, payload: error })
  }
}

export const fetchKaddioForms = () => (dispatch) => {
  getKaddioForms().then(({ data: forms }) => {
    if (forms) {
      const formIdsWithoutPreview = []
      forms.forEach((form) => {
        switch (form.name) {
          case "AAI - Appearance Anxiety Inventory":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/AAI-Appearance-Anxiety-Inventory.png"
            break
          case "ACQ - Agoraphobic Cognitions Questionnaire":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/ACQ-Agoraphobic-Cognitions-Questionnaire.png"
            break
          case "Anamnesfrågor":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/Anamnesfragor.png"
            break
          case "AUDIT - Alcohol Use Disorders Identification Test":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/07/AUDIT-Alcohol-Use-Disorders-Identification-Test.png"
            break
          case "BSQ - Body Sensations Questionnaire":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/BSQ-Body-Sensations-Questionnaire.png"
            break
          case "CLQ - Klaustrofobiformuläret":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/CLQ-Klaustrofobi.png"
            break
          case "DSM-5 Självskattning av aktuella symtom":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/DSM-5-Sjalvskattning-av-aktuella-symtom.png"
            break
          case "EmetQ kortversion - Emetophobia Questionnaire":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/EmetQ-kortversion-1.png"
            break
          case "GAD-7 - Generalised Anxiety Disorder 7-item scale":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/GAD-7-Generalised-Anxiety-Disorder-7-item-scale.png"
            break
          case "IES-R - Impact of Event Scale–Revised":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/IES-R-Impact-of-Event-Scale–Revised.png"
            break
          case "ISI – Insomnia Severity Index":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/ISI-Insomnia-Severity-Index.png"
            break
          case "KEDS - Karolinska Exhaustion Disorder Scale 9":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/KEDS-Karolinska-Exhaustion-Disorder-Scale-9.png"
            break
          case "MADRS-S - Montgomery Åsberg Depression Rating Scale":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/MADRS-S-Montgomery-Asberg-Depression-Rating-Scale.png"
            break
          case "MDQ - Screening för bipolaritet":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/MDQ-Screening-for-bipolaritet.png"
            break
          case "OCI-R - Obsessive Compulsive Inventory- Revised":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/OCI-R-Obsessive-Compulsive-Inventory-Revised.png"
            break
          case "PDSS-SR - Panic Disorder Severity Scale - Self Rated":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/PDSS-SR-Panic-Disorder-Severity-Scale-Self-Rated.png"
            break
          case "PHQ-9 - Patient Health Questionnaire":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/PHQ-9-Patient-Health-Questionnaire.png"
            break
          case "PSS-14 - Perceived Stress Scale":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/PSS-14-Perceived-Stress-Scale.png"
            break
          case "PSWQ - Penn State Worry Questionnaire":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/PSWQ-Penn-State-Worry-Questionnaire.png"
            break
          case "SHAI-14 - Short Health Anxiety Inventory":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/SHAI-14-Short-Health-Anxiety-Inventory.png"
            break
          case "SIAS - Social Interaction Anxiety Scale":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/SIAS-Social-Interaction-Anxiety-Scale.png"
            break
          case "SPS - Social Phobia Scale":
            form.url =
              "https://dinpsykolog.se/wp-content/uploads/2022/05/SPS-Social-Phobia-Scale.png"
            break
          default:
            formIdsWithoutPreview.push(form._id)
            break
        }
      })
      dispatch({
        type: SET_KADDIO_FORMS,
        payload: forms.filter(
          ({ _id }) => !formIdsWithoutPreview.includes(_id)
        ),
      })
    }
  })
}

export const fetchKaddioCaregiverUserId =
  (caregiverSocialSecurity) => (dispatch) => {
    getKaddioUserId(caregiverSocialSecurity, true).then(({ data }) => {
      if (data) {
        dispatch({
          type: SET_KADDIO_CAREGIVER_USER_ID,
          payload: data,
        })
      }
    })
  }

export const fetchKaddioPatientUserId =
  (patientSocialSecurity) => (dispatch) => {
    getKaddioUserId(patientSocialSecurity, false).then(({ data }) => {
      dispatch({
        type: SET_KADDIO_PATIENT_USER_ID,
        payload: data || null,
      })
    })
  }

export const setChatMessagesPagination = (data) => (dispatch) => {
  dispatch({
    type: SET_CHAT_MESSAGES_PAGINATION,
    payload: data,
  })
}

export const postMessage = (chatId, body) => (dispatch, getState) =>
  postChatEntry({ ...body, chatId })
    .then(({ data }) => {
      const chatMessage = {
        ...data[0],
        chatId,
        ...body,
      }
      const {
        chats: { instances },
      } = getState()

      const tmp = [...instances]
      const instanceIndex = tmp.findIndex((instance) => instance.id === chatId)

      tmp[instanceIndex].latestEntry = chatMessage

      dispatch({
        type: SET_INITIAL_MESSAGE,
        payload: tmp,
      })
      dispatch(setChatMessagesPagination(defaultPagination))
      dispatch(fetchChatEntries(chatId, body.toUserId))

      return Promise.resolve()
    })
    .catch((error) => {
      dispatch(
        showAlert(
          "error",
          "Ditt meddelande gick tyvärr inte att skicka, försök gärna igen"
        )
      )
      return Promise.reject(error)
    })

export const setIncomingMessage = (message) => async (dispatch, getState) => {
  const {
    chats: { error, instances: pollInstances },
    user: { userId },
  } = getState()

  if (!error) {
    if (!pollInstances.some(({ id }) => id === message.chatId)) {
      try {
        await dispatch(fetchUserChats(userId, true))
      } catch (err) {
        return
      }
    }

    const {
      chats: {
        activeChat,
        instances,
        patient: { information: patientInfo },
      },
    } = getState()

    const tmp = [...instances]
    const instanceIndex = tmp.findIndex(
      (instance) => instance.id === message.chatId
    )

    if (message.type === entryType.DEFAULT) {
      tmp[instanceIndex].latestEntry = message
    }

    dispatch({
      type: SET_INITIAL_MESSAGE,
      payload: tmp,
    })

    if (message.chatId === activeChat) {
      dispatch({
        type: POST_MESSAGE,
        payload: message,
      })

      chatMessagesSeen(message.chatId, userId)
      dispatch(fetchChatEntries(activeChat, patientInfo.userId))
    }

    dispatch(fetchSessions(userId))
  }
}

export const setLastMessageSeen =
  ({ chatId, userId }) =>
  (dispatch, getState) => {
    const {
      chats: {
        messages,
        activeChat,
        patient: { information: patientInfo },
      },
    } = getState()

    if (activeChat === chatId && patientInfo.userId === userId) {
      const seenMessages = messages.map((msg) => {
        const isCaregiverOrPatientMessage = [
          entryType.DEFAULT,
          entryType.SYSTEM,
        ].includes(msg.type)

        return { ...msg, seen: isCaregiverOrPatientMessage ? true : msg.seen }
      })

      dispatch({
        type: SET_CHATS,
        payload: {
          messages: seenMessages,
        },
      })
    }
  }

export const setActiveChat = (chatId, patient) => (dispatch, getState) => {
  const {
    chats: { patient: statePatient },
  } = getState()

  dispatch({
    type: SET_CHATS,
    payload: {
      patient: {
        ...statePatient,
        information: patient,
      },
      activeChat: chatId,
      switchChatLoading: true,
    },
  })
}

export const resetActiveChat = () => (dispatch) => {
  dispatch({
    type: SET_CHATS,
    payload: {
      messages: [],
      patient: {
        information: {},
        answers: [],
      },
      activeChat: null,
    },
  })
}

export const setUserAvatar = (userId, file) => (dispatch) => {
  imageUpload(userId, file)
    .then(({ data }) => {
      const { url } = data
      dispatch({
        type: SET_USER_AVATAR,
        payload: url,
      })
      dispatch(showAlert("success", "Avatar sparad"))
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data || "Något gick fel med bilduppladdningen"

      dispatch(showAlert("error", errorMessage))
    })
}

export const uploadFile = (file) => (dispatch) => {
  const maxSize = 10 * 1024 * 1024 // 10MB
  if (file.size > maxSize) {
    dispatch(showAlert("error", "Filen är för stor"))
    return
  }
  return fileUpload(file)
    .then(({ data }) => {
      dispatch(showAlert("success", "Fil uppladdad"))
      return data.url
    })
    .catch((err) => {
      if (err.isAxiosError) {
        let message
        switch (err.response?.status) {
          case 422:
            message = "Filtyp som inte stöds"
            break
          case 400:
            message = "Filen är för stor"
            break
          default:
            message = "Något dåligt hände"
        }
        dispatch(showAlert("error", message))
      }
    })
}

export const setJournalized = (sessionId) => (dispatch, getState) => {
  const { sessions } = getState()
  const newSessions = sessions.past.map((el) =>
    el.id === sessionId
      ? {
          ...el,
          journalized: true,
        }
      : el
  )
  dispatch({
    type: SET_JOURNALIZED,
    payload: newSessions,
  })
}

export const removeUserCredentials = () => {
  Cookie.remove("caregiver", { domain: config.cookie_path })
  return { type: LOGOUT_USER }
}

export const fetchSessions = (caregiverId) => (dispatch) => {
  getSessionsByCaregiver(caregiverId).then(({ data }) => {
    if (data) {
      dispatch({
        type: SET_SESSIONS,
        payload: data,
      })
    }
  })
}

export const fetchCalendarEvents = (caregiverId) => (dispatch) => {
  getCaregiverCalendarEvents(caregiverId).then(({ data }) => {
    if (data) {
      dispatch({
        type: SET_CALENDAR_EVENTS,
        payload: {
          bookings: [
            ...convertDatesConsideringDaylightSavingTime(data.bookings),
          ],
          available: [
            ...convertDatesConsideringDaylightSavingTime(data.available),
          ],
        },
      })
    }
  })
}

export const addCalendarAvailability =
  (newAvailability) => (dispatch, getState) => {
    const { calendarEvents } = getState()
    dispatch({
      type: SET_CALENDAR_EVENTS,
      payload: {
        ...calendarEvents,
        available: [
          ...calendarEvents.available,
          ...convertDatesConsideringDaylightSavingTime(newAvailability),
        ],
      },
    })
    dispatch(showAlert("success", "Tillgänglighet sparad"))
  }

export const editCalendarAvailability =
  (editedAvailability) => (dispatch, getState) => {
    const { calendarEvents } = getState()
    const [{ id: editedAvailabilityId }] = editedAvailability
    const calendarAvailabilitiesWithoutEditedAvailability =
      calendarEvents.available.filter(({ id }) => id !== editedAvailabilityId)
    dispatch({
      type: SET_CALENDAR_EVENTS,
      payload: {
        ...calendarEvents,
        available: [
          ...calendarAvailabilitiesWithoutEditedAvailability,
          ...convertDatesConsideringDaylightSavingTime(editedAvailability),
        ],
      },
    })
    dispatch(showAlert("success", "Tillgänglighet sparad"))
  }

export const removeCalendarAvailability =
  (availabilityId, availabilityStartDate, availabilityUpdateType) =>
  (dispatch, getState) => {
    const { calendarEvents } = getState()

    const getFilteredAvailabilities = () => {
      switch (availabilityUpdateType) {
        case updateType.SINGLE:
          return calendarEvents.available.filter(
            ({ id, start }) =>
              id !== availabilityId || start !== availabilityStartDate
          )
        case updateType.FOLLOWING:
          return calendarEvents.available.filter(
            ({ id, start }) =>
              id !== availabilityId || start <= availabilityStartDate
          )
        default:
          return calendarEvents.available.filter(
            ({ id }) => id !== availabilityId
          )
      }
    }
    dispatch({
      type: SET_CALENDAR_EVENTS,
      payload: {
        ...calendarEvents,
        available: getFilteredAvailabilities(),
      },
    })
    dispatch(showAlert("success", "Tillgänglighet raderad"))
  }
