export default {
  isDev: process.env.REACT_APP_IS_DEV === "true",
  company_name: "Din Psykolog",
  redirect_url: process.env.REACT_APP_REDIRECT_URL_AUTH,
  api_url: process.env.REACT_APP_REDIRECT_URL_API,
  ws_url: process.env.REACT_APP_REDIRECT_WS_URL,
  cookie_path: process.env.REACT_APP_COOKIE_PATH,
  kaddioUrl: process.env.REACT_APP_KADDIO_URL,
  terms_and_condition: "https://dinpsykolog.se/villkor",
  privacy_policy: "https://dinpsykolog.se/integritetspolicy/",
  about_us: "https://dinpsykolog.se/#om-oss",
  faq: "https://dinpsykolog.se/vanliga-fragor-psykolog/",
  support: "https://dinpsykolog.se/support-psykolog/",
  gtmId: "GTM-KJHD87Q",
  guideUrl: "https://dinpsykolog.se/psykolog",
  web_site: "https://dinpsykolog.se",
  slackErrorsTestChannelId: "C033VE8GVPZ",
  slackErrorsProdChannelId: "C034CBSR0BE",
}
