/* eslint-disable no-nested-ternary */
import { useEffect, useState, Fragment } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setActiveChat } from "redux/actions"
import { getChatSessionEntries } from "api/getChatSessionEntries"
import TitleDivider from "pages/chat/components/TitleDivider"
import moment from "moment"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { entryType } from "utils/entryType"
import ChatBubble from "./ChatBubble"

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    textAlign: "center",
    "& h2": {
      fontSize: "18px ",
    },
  },
  dialogContent: {
    padding: "8px 8px 20px 8px",
    overflow: "auto",
  },
  dialogActionButtons: {
    borderTop: `2px solid ${theme.palette.gray}`,
  },
  entireChatButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  closeButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      opacity: "0.8",
    },
  },
  noContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    minHeight: "200px",
  },
  error: {
    color: theme.palette.error.main,
  },
}))

const ChatPreviewDialog = ({ onClose, open, session, patient }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const classes = useStyles()
  const [messages, setMessages] = useState([])
  const [messagesLoading, setMessagesLoading] = useState(true)
  const [error, setError] = useState(null)

  const resetAllData = () => {
    setMessagesLoading(true)
    setMessages([])
    setError(null)
  }

  useEffect(() => {
    if (session.chatId && session.id) {
      resetAllData()
      const getChatSessionMessages = async () => {
        try {
          const { data } = await getChatSessionEntries(
            session.chatId,
            session.id
          )
          setMessages(
            data.filter((message) => message.type !== entryType.SYSTEM_PATIENT)
          )
          setMessagesLoading(false)
        } catch (error) {
          setError("Något gick fel, försök igen.")
          setMessagesLoading(false)
        }
      }
      getChatSessionMessages()
    }
  }, [session.chatId, session.id])

  const openEntireChat = () => {
    if (patient.information && session.chatId) {
      dispatch(setActiveChat(session.chatId, patient.information))
    }
    navigate("/")
  }

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        {session.patient}
        <br />- Session {session.sessionNumber} -
      </DialogTitle>
      {messagesLoading ? (
        <Box className={classes.noContent}>
          <CircularProgress size={50} color="primary" />
        </Box>
      ) : error ? (
        <Box className={classes.noContent}>
          <Typography component="span" className={classes.error}>
            {error}
          </Typography>
        </Box>
      ) : (
        <DialogContent className={classes.dialogContent}>
          {messages.map((message, index) => {
            const { id, createdAt } = message
            if (
              index > 0 &&
              moment(messages[index - 1]?.createdAt).isSame(
                moment(createdAt),
                "day"
              )
            ) {
              return (
                <Fragment key={`${id}_${createdAt}`}>
                  <ChatBubble
                    key={id}
                    patient={patient}
                    userId={session.caregiverId}
                    message={message}
                  />
                </Fragment>
              )
            }
            return (
              <Fragment key={`${id}_${createdAt}`}>
                <TitleDivider timeStamp={createdAt} />
                <ChatBubble
                  key={id}
                  patient={patient}
                  userId={session.caregiverId}
                  message={message}
                />
              </Fragment>
            )
          })}
        </DialogContent>
      )}
      <DialogActions className={classes.dialogActionButtons}>
        <Button
          color="default"
          onClick={() => openEntireChat()}
          autoFocus
          className={classes.entireChatButton}
        >
          Öppna hela chatten
        </Button>
        <Button onClick={onClose} className={classes.closeButton}>
          Stäng
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChatPreviewDialog
