import React from "react"
import moment from "moment"
import { Divider, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  divider: {
    flex: 1,
    backgroundColor: theme.palette.gray,
  },
  text: {
    lineHeight: "15px",
    fontSize: "12px",
    color: theme.palette.dark_gray,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  dividerSession: {
    flex: 1,
    backgroundColor: theme.palette.primary.main,
  },
  textSession: {
    lineHeight: "15px",
    fontSize: "14x",
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

const TitleDivider = React.forwardRef(({ timeStamp, sessionNum }, ref) => {
  const classes = useStyles()

  const renderContent = () => {
    if (timeStamp) {
      return (
        <>
          <Divider className={classes.divider} />
          <Typography className={classes.text} component="span">
            {moment(timeStamp).format("D MMM")}
          </Typography>
          <Divider className={classes.divider} />
        </>
      )
    }
    if (sessionNum) {
      return (
        <>
          <Divider className={classes.dividerSession} />
          <Typography className={classes.textSession} component="span">
            {`Session ${sessionNum}`}
          </Typography>
          <Divider className={classes.dividerSession} />
        </>
      )
    }
    return null
  }

  if (!timeStamp && !sessionNum) return null
  return (
    <div className={classes.root} ref={ref}>
      {renderContent()}
    </div>
  )
})

export default TitleDivider
