import React, { Fragment } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { makeStyles } from "@mui/styles"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import Header from "./Header"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
  },
  titleContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingRight: 59,
  },
  title: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: "bold",
  },
  displayName: {
    color: theme.palette.common.black,
  },
}))

const BackHeader = ({ children, width, fullName, ...rest }) => {
  const classes = useStyles()
  const theme = useTheme()

  if (useMediaQuery(theme.breakpoints.down("sm"))) {
    return (
      <>
        <div className={classes.root}>
          <AppBar position="static" className={classes.appBar}>
            <Toolbar>
              <IconButton component={Link} to="/">
                <ArrowBackIcon fontSize="large" color="primary" />
              </IconButton>
              <Box className={classes.titleContainer}>
                <Typography variant="h6" className={classes.title}>
                  {fullName}
                </Typography>
              </Box>
            </Toolbar>
          </AppBar>
        </div>
        {children}
      </>
    )
  }

  return <Header>{children}</Header>
}

const mapStateToProps = ({ user }) => {
  const { firstName, lastName } = user
  const fullName = `${firstName} ${lastName}`
  return { fullName }
}

export default connect(mapStateToProps)(BackHeader)
