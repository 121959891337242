import React, { createRef } from "react"
import { connect } from "react-redux"
import DefaultAvatar from "components/DefaultAvatar"
import { Badge, Fab } from "@mui/material"
import { makeStyles } from "@mui/styles"
import AddIcon from "@mui/icons-material/Add"

import { setUserAvatar } from "redux/actions"

const useStyles = makeStyles((theme) => ({
  root: {},
  add: {
    width: 26,
    height: 26,
    minHeight: 15,
    backgroundColor: theme.palette.white,
  },
  input: {
    display: "none",
  },
}))

const ChangeAvatar = ({ dispatch, userId, avatarUrl, firstName, lastName }) => {
  const classes = useStyles()
  const fileRef = createRef()

  return (
    <>
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={
          <Fab className={classes.add} onClick={() => fileRef.current.click()}>
            <AddIcon color="primary" />
          </Fab>
        }
      >
        <DefaultAvatar
          size={75}
          src={avatarUrl}
          name={`${firstName} ${lastName}`}
        />
      </Badge>
      <input
        type="file"
        accept="image/*"
        ref={fileRef}
        className={classes.input}
        onClick={(e) => {
          e.target.value = ""
        }}
        onChange={(event) => {
          dispatch(setUserAvatar(userId, event.target.files[0]))
        }}
      />
    </>
  )
}

const mapStateToProps = ({ user }) => {
  const { userId, avatarUrl, firstName, lastName } = user
  return { userId, avatarUrl, firstName, lastName }
}

export default connect(mapStateToProps)(ChangeAvatar)
