import React, { useEffect, useState, useMemo, useContext } from "react"
import { connect } from "react-redux"
import moment from "moment"
import { sub, isBefore } from "date-fns"
import {
  Box,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material"
import { withStyles, makeStyles } from "@mui/styles"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ChatCard, { chatTypes } from "pages/chat/components/ChatCard"
import { setActiveChat } from "redux/actions"
import { anonymousModeContext } from "contexts"

const Accordion = withStyles((theme) => ({
  root: {
    border: 0,
    boxShadow: "none",
    position: "relative",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      flex: 1,
      margin: 0,
      borderBottom: `1px solid ${theme.palette.gray}`,
    },
  },
  expanded: {},
}))(MuiAccordion)

const AccordionSummary = withStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    backgroundColor: theme.palette.lighter_gray,
    borderBottom: `1px solid ${theme.palette.gray}`,
    "&$expanded": {
      minHeight: 53,
    },
  },
  content: {
    overflow: "hidden",
    "& .MuiTypography-root": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 18,
    },
    "&$expanded": {
      margin: "12px 0",
      "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
  expanded: {},
}))(MuiAccordionSummary)

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.lighter_gray,
    borderLeft: `0.5px solid ${theme.palette.gray}`,
    borderRight: `0.5px solid ${theme.palette.gray}`,
  },
  ChatContainer: {
    overflowY: "scroll",
    overflowX: "hidden",
    position: "absolute",
    top: 56,
    bottom: 0,
    left: 0,
    right: 0,
    "&::-webkit-scrollbar": {
      height: 1,
      width: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#999",
      borderRadius: 4,
    },
  },
  ToggleContainer: {
    marginLeft: "10px",
  },
}))

const getArrays = (instances, activeChatId, sessions) => {
  // NOTE: hierarchy of list
  const chatInstances = [
    {
      active: [],
      notifications: [],
      messages: [],
    },
    {
      active: [],
      notifications: [],
      messages: [],
    },
  ]
  const [activeInstances, inactiveInstances] = chatInstances

  const oneWeek = 7
  const hasExpiredSessionLessThanWeekAgo = (chat) =>
    !sessions.active.find(({ userId }) => userId === chat.patientId) &&
    sessions.past.find(
      ({ userId, endedAt }) =>
        userId === chat.patientId &&
        isBefore(sub(new Date(), { days: oneWeek }), new Date(endedAt))
    )
  const lastMessageWasWrittenByPatient = (chatData) =>
    chatData?.latestEntry?.fromUserId === chatData?.patientId

  // sort by newest chat message
  const sortedInstances = instances.sort((a, b) =>
    moment(b.latestEntry?.createdAt).diff(moment(a.latestEntry?.createdAt))
  )

  const filterInstance = (instance, item) => {
    const userId = item.caregiverId
    const { fromUserId, systemEntry } = item?.latestEntry || {}

    if (item.id === activeChatId) {
      instance.active.push({ ...item, type: chatTypes.active })
      return
    }

    if ((fromUserId !== userId || systemEntry) && item.active) {
      instance.notifications.push({ ...item, type: chatTypes.unread })
      return
    }

    instance.messages.push({ ...item, type: chatTypes.read })
  }

  sortedInstances.forEach((instance) => {
    const hasActiveSession = sessions.active.find(
      ({ userId }) => userId === instance.patientId
    )
    const isActive = instance.active

    if (
      (hasActiveSession && isActive) ||
      (isActive &&
        hasExpiredSessionLessThanWeekAgo(instance) &&
        lastMessageWasWrittenByPatient(instance))
    ) {
      filterInstance(activeInstances, instance)
    } else {
      filterInstance(inactiveInstances, instance)
    }
  })

  const flattenArray = (obj) =>
    Object.values(obj).reduce((prev, current) => [...prev, ...current])

  return [flattenArray(activeInstances), flattenArray(inactiveInstances)]
}

const SideBar = ({ dispatch, chats, sessions }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState("chat")
  const [anonymousMode, toggleAnonymousContext] =
    useContext(anonymousModeContext)
  const { instances, activeChat, switchChatLoading } = chats

  const [activeInstances, inactiveInstances] = useMemo(
    () => getArrays(instances, activeChat, sessions),
    [instances, activeChat, sessions]
  )

  useEffect(() => {
    localStorage.setItem("anonymousMode", anonymousMode)
  }, [anonymousMode])

  useEffect(() => {
    if (activeChat) {
      const isArchived = !!inactiveInstances.find(
        (item) => item.id === activeChat
      )
      setExpanded(isArchived ? "archive" : "chat")
    }
  }, [activeChat])

  const handleChange = (panel) => (_, newExpanded) => {
    if (newExpanded) setExpanded(panel)
  }

  const checkActiveChat = (activeChatId, chatId) => activeChatId === chatId

  return (
    <Box className={classes.root}>
      <Accordion expanded={expanded === "chat"} onChange={handleChange("chat")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Aktiva patienter</Typography>
        </AccordionSummary>
        <Box className={classes.ChatContainer}>
          {activeInstances.map((instance) => (
            <ChatCard
              initialMessage={instance?.latestEntry || {}}
              key={instance.id}
              instance={instance}
              onClick={(chatId, patient) => {
                if (
                  !checkActiveChat(activeChat, instance.id) &&
                  !switchChatLoading
                ) {
                  dispatch(setActiveChat(chatId, patient))
                }
              }}
            />
          ))}
        </Box>
      </Accordion>
      <Accordion
        expanded={expanded === "archive"}
        onChange={handleChange("archive")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Inaktiva patienter</Typography>
        </AccordionSummary>
        <Box className={classes.ChatContainer}>
          {inactiveInstances.map((instance) => (
            <ChatCard
              initialMessage={instance?.latestEntry || {}}
              key={instance.id}
              instance={instance}
              onClick={(chatId, patient) => {
                if (
                  !checkActiveChat(activeChat, instance.id) &&
                  !switchChatLoading
                ) {
                  dispatch(setActiveChat(chatId, patient))
                }
              }}
            />
          ))}
        </Box>
      </Accordion>
      <FormGroup className={classes.ToggleContainer}>
        <FormControlLabel
          control={<Switch color="primary" />}
          checked={anonymousMode}
          onChange={() => toggleAnonymousContext()}
          label="Dölj känslig info"
        />
      </FormGroup>
    </Box>
  )
}

const mapStateToProps = ({ chats, sessions }) => ({ chats, sessions })

export default connect(mapStateToProps)(SideBar)
