import React from "react"
import { Controller } from "react-hook-form"
import {
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
} from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  label: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}))

const Checkbox = ({ name = "", label = "", control, defaultValue }) => {
  const classes = useStyles()
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={() => (
        <MuiFormControlLabel
          label={label}
          labelPlacement="end"
          className={classes.label}
          control={
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue}
              render={({ field }) => (
                <MuiCheckbox
                  checked={field.value}
                  inputRef={field.ref}
                  onChange={(e) => field.onChange(e.target.checked)}
                  onBlur={field.onBlur}
                  color="primary"
                />
              )}
            />
          }
        />
      )}
    />
  )
}

export default Checkbox
