import React from "react"
import { connect } from "react-redux"
import { Box, Typography, Badge } from "@mui/material"
import { makeStyles } from "@mui/styles"
import classNames from "classnames"
import DefaultAvatar from "components/DefaultAvatar"
import AnonymousModeWrapper from "components/AnonymousModeWrapper"
import moment from "moment"
import isEmpty from "lodash.isempty"

export const chatTypes = {
  active: "active",
  read: "read",
  unread: "unread",
}

const useStyles = makeStyles((theme) => {
  const chatBackgroundColors = {
    [chatTypes.active]: {
      default: theme.palette.primary.main,
      hover: theme.palette.primary.dark,
    },
    [chatTypes.read]: {
      default: theme.palette.light_gray,
      hover: theme.palette.gray,
    },
    [chatTypes.unread]: {
      default: theme.palette.secondary.light,
      hover: theme.palette.secondary.main,
    },
  }

  return {
    wrapper: {
      boxSizing: "border-box",
      width: "100%",
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(4),
      "&:last-of-type": {
        paddingBottom: theme.spacing(2),
      },
    },
    customBadge: ({ type }) => ({
      width: 12,
      height: 12,
      borderRadius: "50%",
      right: theme.spacing(1),
      top: `calc(50% + ${theme.spacing(1)})`,
      transform: "translateY(-50%)",
      backgroundColor: chatBackgroundColors[type]?.hover,
    }),
    root: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      border: `1px solid ${theme.palette.gray}`,
      borderRadius: 6,
      padding: theme.spacing(1),
      cursor: "pointer",
    },
    chatBox: ({ type }) => ({
      backgroundColor: chatBackgroundColors[type]?.default,
      "&:hover": {
        backgroundColor: chatBackgroundColors[type]?.hover,
        "& .MuiTypography-root": {
          color: theme.palette.white,
        },
      },
      "& .MuiTypography-root": {
        color:
          type === chatTypes.active ? theme.palette.white : theme.palette.black,
      },
    }),
    textContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minWidth: 0,
      marginLeft: theme.spacing(2),
      "& > p": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    nameAndTime: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "& > p:first-child": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "& > p:last-child": {
        whiteSpace: "nowrap",
        paddingLeft: theme.spacing(1),
      },
    },
    text: {
      fontSize: 14,
      color: theme.palette.black,
      fontWeight: theme.typography.fontWeightRegular,
      "&.bold": {
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  }
})

const isTimestampToday = (timestamp) => {
  const today = moment()
  const createdAt = moment(timestamp)

  const dayDiff = today.diff(createdAt, "days")

  if (dayDiff) return createdAt.format("D MMM")

  return createdAt.format("HH:mm")
}

const ChatCard = ({ instance, patients, initialMessage, onClick }) => {
  const patient = patients.find(({ userId }) => userId === instance.patientId)
  const classes = useStyles({ type: instance.type })
  const checkUnreadChats = () => chatTypes.unread === instance.type

  if (!patient) {
    return null
  }

  const { firstName, lastName, avatarUrl } = patient
  const { data = "", createdAt = {} } = initialMessage

  return (
    <Badge
      invisible={!checkUnreadChats()}
      variant="dot"
      className={classes.wrapper}
      classes={{ badge: classes.customBadge }}
    >
      <Box
        className={classNames(classes.root, classes.chatBox)}
        onClick={() => onClick(instance.id, patient)}
      >
        <AnonymousModeWrapper>
          <DefaultAvatar
            size={46}
            src={avatarUrl}
            type={instance.type}
            name={`${firstName} ${lastName}`}
          />
        </AnonymousModeWrapper>
        <Box className={classes.textContainer}>
          <Box className={classes.nameAndTime}>
            <Typography className={classNames(classes.text, "bold")}>
              <AnonymousModeWrapper>
                {firstName} {lastName}
              </AnonymousModeWrapper>
            </Typography>
            <Typography className={classNames(classes.text, "bold")}>
              {!isEmpty(createdAt) ? isTimestampToday(createdAt) : null}
            </Typography>
          </Box>
          <Typography
            className={classNames(classes.text, { bold: checkUnreadChats() })}
          >
            <AnonymousModeWrapper>{data}</AnonymousModeWrapper>
          </Typography>
        </Box>
      </Box>
    </Badge>
  )
}

const mapStateToProps = ({ patients }) => ({ patients })

export default connect(mapStateToProps)(ChatCard)
