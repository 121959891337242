import React from "react"
import { Box, Hidden, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import ChangeAvatar from "components/ChangeAvatar"
import TouchButton from "components/TouchButton"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ProfileForm from "./components/Form"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  container: {
    flexGrow: 1,
    backgroundColor: "rgb(246,246,246)",
  },
}))

const Profile = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <Box mx={2} mt={2}>
          <TouchButton to="/">
            <ArrowBackIcon />
            <Box ml={1}>
              <Typography color="primary">Tillbaka till chatten</Typography>
            </Box>
          </TouchButton>
          <Box ml={2}>
            <Typography color="primary" variant="h6">
              Min Profil
            </Typography>
          </Box>
        </Box>
      </Hidden>
      <Box className={classes.container} m={2} p={2}>
        <Box pb={4}>
          <ChangeAvatar />
        </Box>
        <ProfileForm />
      </Box>
    </div>
  )
}

export default Profile
