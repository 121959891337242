import { Views, Navigate } from "react-big-calendar"
import { Button } from "@mui/material"
import {
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import {
  addMonths,
  subMonths,
  isSameDay,
  isSameWeek,
  isSameMonth,
  isBefore,
  isAfter,
} from "date-fns"

const fontFamily = () =>
  ["Montserrat", "Roboto", "Helvetica", "Arial", "sans-serif"].join(",")
const useStyles = makeStyles((theme) => ({
  toolbarButton: {
    display: "inline-flex !important",
    alignItems: "flex-end !important",
    fontSize: "0.8rem",
    "&:hover, &:focus, &:active, &:active:hover, &:active:focus": {
      backgroundColor: `${theme.palette.primary.light} !important`,
      borderColor: `${theme.palette.primary.light} !important`,
      color: `${theme.palette.white} !important`,
    },
    "& svg": {
      fontSize: "0.95rem",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0.375rem 0.375rem",
    },
  },
  addButton: {
    fontSize: "0.8rem",
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.white} !important`,
    "&:hover": {
      opacity: 0.8,
    },
  },
  "@global": {
    ".rbc-calendar": {
      marginTop: theme.spacing(3),
      fontFamily,
      "& button": {
        fontFamily,
      },
      "& .rbc-events-container": {
        "& .rbc-background-event": {
          backgroundColor: "rgb(228 165 30)",
          border: "1px solid rgb(228 165 30)",
          width: "calc(100% + 10px) !important",
        },
        "& .rbc-event-content": {
          fontSize: "90%",
          lineHeight: 1.1,
        },
        "& .rbc-event": {
          justifyContent: "center",
          width: "100% !important",
        },
      },
      "& .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-date-cell": {
        borderRight: "1px solid rgb(221, 221, 221)",
      },
      "& .rbc-agenda-event-cell": {
        cursor: "pointer",
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
    },
    ".rbc-toolbar": {
      fontFamily,
      marginBottom: theme.spacing(3),
      // this breakpoint is specific to react-big-calendar
      [theme.breakpoints.down(1061)]: {
        justifyContent: "space-between",
        rowGap: theme.spacing(1),
      },
      "& .rbc-toolbar-label": {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
      "& button.rbc-active": {
        "&, &:hover, &:focus": {
          backgroundColor: `${theme.palette.primary.main} !important`,
          borderColor: `${theme.palette.primary.main} !important`,
          color: `${theme.palette.white} !important`,
        },
      },
      "& .rbc-btn-group:last-of-type": {
        marginRight: theme.spacing(1),
        "& + button": {
          marginLeft: 0,
        },
        [theme.breakpoints.down("sm")]: {
          display: "grid",
          width: "100%",
          marginTop: theme.spacing(1),
          "& button": {
            marginLeft: 0,
            "&:first-child": {
              borderBottomLeftRadius: 0,
              borderTopRightRadius: "4px",
            },
            "&:last-child": {
              borderBottomLeftRadius: "4px",
              borderTopRightRadius: 0,
            },
          },
        },
      },
    },
    ".rbc-month-row": {
      flexBasis: "100px",
      "& .rbc-event-content": {
        fontSize: "70%",
      },
    },
  },
}))

const CalendarToolbar = ({
  view,
  label,
  date,
  onNavigate,
  onView,
  handleOpenAvailabilityDialog,
}) => {
  const classes = useStyles()

  const getClassName = (viewOption) =>
    view === viewOption
      ? `rbc-active ${classes.toolbarButton}`
      : classes.toolbarButton

  const isNavigationButtonDisabled = (navigationOption) => {
    const lastVisibleDate =
      navigationOption === Navigate.NEXT
        ? addMonths(new Date(), 3)
        : subMonths(new Date(), 3)
    const isCurrentDateBeforeLastVisibleDate = isBefore(date, lastVisibleDate)
    const isCurrentDateAfterLastVisibleDate = isAfter(date, lastVisibleDate)
    switch (view) {
      case Views.DAY:
        return navigationOption === Navigate.NEXT
          ? isSameDay(date, lastVisibleDate) ||
              isCurrentDateAfterLastVisibleDate
          : isSameDay(date, lastVisibleDate) ||
              isCurrentDateBeforeLastVisibleDate
      case Views.WEEK:
      case Views.WORK_WEEK:
        return navigationOption === Navigate.NEXT
          ? isSameWeek(date, lastVisibleDate) ||
              isCurrentDateAfterLastVisibleDate
          : isSameWeek(date, lastVisibleDate) ||
              isCurrentDateBeforeLastVisibleDate
      case Views.MONTH:
      case Views.AGENDA:
        return navigationOption === Navigate.NEXT
          ? isSameMonth(date, lastVisibleDate) ||
              isCurrentDateAfterLastVisibleDate
          : isSameMonth(date, lastVisibleDate) ||
              isCurrentDateBeforeLastVisibleDate
      default:
        return false
    }
  }

  return (
    <div className="rbc-toolbar">
      <div className="rbc-btn-group">
        <Button
          className={classes.toolbarButton}
          onClick={() => onNavigate(Navigate.TODAY)}
        >
          I dag
        </Button>
        <Button
          className={classes.toolbarButton}
          disabled={isNavigationButtonDisabled(Navigate.PREVIOUS)}
          onClick={() => onNavigate(Navigate.PREVIOUS)}
        >
          <ArrowBackIcon />
        </Button>
        <Button
          className={classes.toolbarButton}
          disabled={isNavigationButtonDisabled(Navigate.NEXT)}
          onClick={() => onNavigate(Navigate.NEXT)}
        >
          <ArrowForwardIcon />
        </Button>
      </div>
      <div className="rbc-toolbar-label">{label}</div>
      <div className="rbc-btn-group">
        <Button
          className={getClassName(Views.MONTH)}
          onClick={() => onView(Views.MONTH)}
        >
          Månad
        </Button>
        <Button
          className={getClassName(Views.WEEK)}
          onClick={() => onView(Views.WEEK)}
        >
          Vecka
        </Button>
        <Button
          className={getClassName(Views.WORK_WEEK)}
          onClick={() => onView(Views.WORK_WEEK)}
        >
          Arbetsvecka
        </Button>
        <Button
          className={getClassName(Views.DAY)}
          onClick={() => onView(Views.DAY)}
        >
          Dag
        </Button>
        <Button
          className={getClassName(Views.AGENDA)}
          onClick={() => onView(Views.AGENDA)}
        >
          Dagens möten
        </Button>
      </div>
      <Button
        className={classes.addButton}
        onClick={() => handleOpenAvailabilityDialog()}
      >
        Lägg till tillgänglighet
      </Button>
    </div>
  )
}

export default CalendarToolbar
