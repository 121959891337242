import React, { useLayoutEffect, useState, useRef, useEffect } from "react"
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import {
  Box,
  AppBar,
  Toolbar,
  Drawer,
  IconButton,
  Button,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Backdrop,
  Container,
  Menu,
  MenuItem,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Videocam as VideocamIcon,
} from "@mui/icons-material/"
import config from "config"
import { fetchCalendarEvents } from "redux/actions"
import { Logo } from "assets"
import { format } from "date-fns"
import { sv } from "date-fns/locale"
import MeetingDialog from "pages/calendar/components/MeetingDialog"
import MeetingRoomDialog from "pages/calendar/components/MeetingRoomDialog"
import DefaultAvatar from "./DefaultAvatar"
import BurgerMenuIcon from "./BurgerMenuIcon"

const COMPANY_NAME = config.company_name
const DRAWER_WIDTH = "80%"
const CURRENT_YEAR = new Date().getFullYear()

const menuItems = [
  {
    key: "guideUrl",
    text: "Psykologguide",
  },
  {
    key: "sessions",
    text: "Journalföring",
    routeRedirect: true,
  },
  {
    key: "calendar",
    text: "Kalender",
    routeRedirect: true,
  },
  {
    key: "support",
    text: "Support",
  },
]

const useWindowHeight = () => {
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    function updateHeight() {
      setHeight(window.innerHeight)
    }
    window.addEventListener("resize", updateHeight)
    updateHeight()
    return () => window.removeEventListener("resize", updateHeight)
  }, [])
  return height
}

const useStyles = makeStyles((theme) => ({
  root: (height) => ({
    height,
    position: "relative",
    display: "flex",
    flexDirection: "column",
  }),
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    height: 64,
  },
  titleContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "150px",
    },
  },
  drawer: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    top: "64px",
  },
  displayName: {
    color: theme.palette.black,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
  },
  menuPaper: {
    borderRadius: 0,
  },
  menuItem: {
    color: theme.palette.black,
    lineHeight: "36px",
  },
  menuDivider: {
    margin: "0 8px",
    backgroundColor: theme.palette.light_gray,
  },
  videoButton: {
    textTransform: "none",
    textDecoration: "underline",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "0.89rem",
    color: theme.palette.primary.main,
    "&:hover, &:active, &:focus": {
      background: "none",
      textDecoration: "underline",
    },
    [theme.breakpoints.up("md")]: {
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
    [theme.breakpoints.down("md")]: {
      "& .MuiListItemIcon-root": {
        minWidth: theme.spacing(4),
        color: "inherit",
      },
      "& .MuiListItemText-root > *": {
        fontWeight: "inherit",
        color: "inherit",
      },
    },
  },
}))

const toggleDrawer = (isOpen, setOpen) => (event) => {
  if (
    event.type === "keydown" &&
    (event.key === "Tab" || event.key === "Shift")
  ) {
    return
  }

  setOpen(isOpen)
}

const ListItemLink = (props) => {
  const {
    icon,
    primary,
    to,
    routeRedirect,
    route,
    videoMeetingFormattedStart,
    onVideoMeetingLinkClick,
    className,
  } = props
  const navigate = useNavigate()

  const handleNavigation = () => {
    if (videoMeetingFormattedStart) {
      onVideoMeetingLinkClick()
    } else if (routeRedirect) {
      navigate(`/${route}`)
    } else {
      window.open(to, "_blank")
    }
  }

  return (
    <ListItem button onClick={() => handleNavigation()} className={className}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={primary} />
    </ListItem>
  )
}

const RenderDrawer = ({
  isOpen,
  setOpen,
  videoMeetingFormattedStart,
  onVideoMeetingLinkClick,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <Drawer
      variant="persistent"
      open={isOpen}
      onClose={toggleDrawer(false, setOpen)}
      {...rest}
    >
      <List>
        <ListItemLink
          primary={
            videoMeetingFormattedStart ? `${videoMeetingFormattedStart}` : null
          }
          secondary={videoMeetingFormattedStart}
          icon={<VideocamIcon />}
          className={classes.videoButton}
          sameWindow
          videoMeetingFormattedStart={videoMeetingFormattedStart}
          onVideoMeetingLinkClick={onVideoMeetingLinkClick}
          to={videoMeetingFormattedStart ? null : "/video"}
        />

        {menuItems.map((item) => (
          <ListItemLink
            key={item.key}
            route={item.key}
            to={config[item.key]}
            routeRedirect={item.routeRedirect}
            primary={item.text}
          />
        ))}
        <Divider />
        <ListItem>
          <Typography variant="caption">
            &#169; {COMPANY_NAME} {CURRENT_YEAR}
          </Typography>
        </ListItem>
      </List>
    </Drawer>
  )
}

const Header = ({ children, user, calendarEvents, dispatch, ...rest }) => {
  const height = useWindowHeight()
  const classes = useStyles(height)
  const [isOpen, setOpen] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [nextBooking, setNextBooking] = useState(null)
  const [openMeetingDialog, setOpenMeetingDialog] = useState(false)
  const [openMeetingRoomDialog, setOpenMeetingRoomDialog] = useState(false)
  const { avatarUrl, firstName, lastName, userId } = user
  const iconButtonRef = useRef()
  const navigate = useNavigate()
  const videoMeetingFormattedStart = nextBooking
    ? `Kommande videomöte: ${format(new Date(nextBooking.start), "d MMMM", {
        locale: sv,
      })}
kl ${format(new Date(nextBooking.start), "HH.mm", {
        locale: sv,
      })}`
    : null

  useEffect(() => {
    dispatch(fetchCalendarEvents(userId))
  }, [dispatch])

  useEffect(() => {
    if (calendarEvents) {
      const futureEvents = calendarEvents.bookings.filter(
        (event) => event.start > new Date(Date.now())
      )
      futureEvents.sort(
        (startTimeFirst, startTimeSecond) =>
          startTimeFirst.start - startTimeSecond.start
      )
      setNextBooking(futureEvents[0])
    }
  }, [calendarEvents])

  const handleJoinMeeting = () => {
    setOpenMeetingDialog(false)
    setOpenMeetingRoomDialog(true)
  }

  const renderDesktopMenuItems = () => (
    <>
      {videoMeetingFormattedStart && (
        <Button
          disableRipple
          component={nextBooking ? null : Link}
          to={nextBooking ? null : "/calendar"}
          className={classes.videoButton}
          onClick={nextBooking ? () => setOpenMeetingDialog(true) : null}
        >
          <VideocamIcon />
          {videoMeetingFormattedStart}
        </Button>
      )}
      {menuItems.map((item) => (
        <Button
          color="default"
          key={item.key}
          onClick={() =>
            item.routeRedirect
              ? navigate(`/${item.key}`)
              : window.open(config[item.key], "_blank")
          }
        >
          {item.text}
        </Button>
      ))}
    </>
  )

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Hidden mdUp>
              <IconButton onClick={toggleDrawer(!isOpen, setOpen)}>
                <BurgerMenuIcon open={isOpen} />
              </IconButton>
            </Hidden>

            <Box className={classes.titleContainer}>
              <Logo
                className={classes.logo}
                onClick={() => window.open(config.web_site, "_blank")}
              />
            </Box>

            <Hidden mdDown>{renderDesktopMenuItems()}</Hidden>

            <IconButton onClick={() => setMenuOpen(true)} ref={iconButtonRef}>
              <DefaultAvatar
                src={avatarUrl}
                name={`${firstName} ${lastName}`}
              />
              {isMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Toolbar>
        </AppBar>

        <Menu
          classes={{ paper: classes.menuPaper }}
          elevation={3}
          anchorEl={iconButtonRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={isMenuOpen}
          onClose={() => setMenuOpen(false)}
        >
          <MenuItem className={classes.menuItem} component={Link} to="/profile">
            Min Profil
          </MenuItem>
          <Divider className={classes.menuDivider} />
          <MenuItem
            className={classes.menuItem}
            component={Link}
            to="/sessions"
          >
            Journalföring
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            component={Link}
            to="/calendar"
          >
            Kalender
          </MenuItem>
        </Menu>

        <RenderDrawer
          className={classes.Drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          isOpen={isOpen}
          setOpen={setOpen}
          videoMeetingFormattedStart={videoMeetingFormattedStart}
          onVideoMeetingLinkClick={() => {
            setOpenMeetingDialog(true)
          }}
        />
        <Backdrop
          className={classes.backdrop}
          open={isOpen}
          onClick={toggleDrawer(false, setOpen)}
        />
      </div>
      <Container maxWidth="lg" disableGutters className={classes.container}>
        {children}
      </Container>
      <MeetingDialog
        openDialog={openMeetingDialog}
        onClose={() => setOpenMeetingDialog(false)}
        meeting={nextBooking}
        onJoinMeeting={handleJoinMeeting}
      />
      <MeetingRoomDialog
        openDialog={openMeetingRoomDialog}
        onClose={() => setOpenMeetingRoomDialog(false)}
        meetingRoomUrl={nextBooking ? nextBooking.url : null}
      />
    </div>
  )
}

const mapStateToProps = ({ user, calendarEvents }) => ({ user, calendarEvents })

export default connect(mapStateToProps)(Header)
