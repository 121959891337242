import { Box, Button, Modal, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import React, { useState } from "react"
import { createInstantMeeting } from "api/createInstantMeeting"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    backgroundColor: theme.palette.lighter_gray,
    padding: theme.spacing(4),
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    outline: "none",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "50%",
    },
  },
  questionText: {
    color: [theme.palette.primary.main],
    textAlign: "center",
    fontWeight: "600",
    padding: [theme.spacing(2, 2, 0, 2)],
    [theme.breakpoints.down("md")]: {
      padding: [theme.spacing(1, 3, 0, 3)],
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: [theme.spacing(1, 2, 0, 2)],
    },
  },
  buttonContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginTop: theme.spacing(4),
  },
  button: {
    display: "flex",
    flex: 1,
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
    textTransform: "none",
  },
}))

const VideoMeetingModal = ({ open, setVideoModalOpen, chatId }) => {
  const classes = useStyles()
  const [error, setError] = useState("")

  const createVideoCall = async () => {
    try {
      setError("")
      await createInstantMeeting(chatId)
      setVideoModalOpen(false)
    } catch (err) {
      setError(err)
    }
  }

  const renderText = (error) => {
    if (error?.length > 0) {
      return <Typography>Something went wrong</Typography>
    }
    return (
      <Box>
        <Typography className={classes.questionText}>
          You are about to send an instant video meeting link.
        </Typography>
        <Typography className={classes.questionText}>
          Are you sure you want to proceed?
        </Typography>
      </Box>
    )
  }

  return (
    <Modal
      className={classes.root}
      open={open}
      onClose={() => setVideoModalOpen(false)}
    >
      <Box className={classes.wrapper}>
        {renderText(error)}
        <Box className={classes.buttonContainer}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={createVideoCall}
          >
            Yes
          </Button>
          <Button
            className={classes.button}
            onClick={() => setVideoModalOpen(false)}
          >
            No
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default VideoMeetingModal
