import React, { useEffect, createRef } from "react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"
import moment from "moment"
import "moment/locale/sv"
import IdleTimer from "react-idle-timer"
import { Provider } from "react-redux"
import store from "redux/store"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import theme from "theme"
import Header from "components/Header"
import BackHeader from "components/BackHeader"
import LocalStorageListenerWrapper from "components/LocalStorageListenerWrapper"
import Profile from "pages/profile"
import Sessions from "pages/sessions"
import Chat from "pages/chat"
import Calendar from "pages/calendar"
import { getCaregiverFromCookie } from "utils/getCaregiverFromCookie"
import Alert from "components/Alert"
import {
  removeUserCredentials,
  setIncomingMessage,
  setLastMessageSeen,
  setJournalized,
} from "redux/actions"
import io from "socket.io-client"
import config from "config"
import AuthRoute from "./AuthRoute"
import withClearCache from "../clearCache"

// 60 minutes
const IDLE_TIMER = 1000 * 60 * 60
const ClearCacheComponent = withClearCache(MainApp)

function App() {
  return <ClearCacheComponent />
}

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <AuthRoute>
              <Header>
                <Chat />
              </Header>
            </AuthRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <AuthRoute>
              <BackHeader>
                <Profile />
              </BackHeader>
            </AuthRoute>
          }
        />
        <Route
          path="/sessions"
          element={
            <AuthRoute>
              <BackHeader>
                <Sessions />
              </BackHeader>
            </AuthRoute>
          }
        />
        <Route
          path="/calendar"
          element={
            <AuthRoute>
              <BackHeader>
                <Calendar />
              </BackHeader>
            </AuthRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  )
}

function MainApp() {
  moment.locale("sv")
  const idleTimerRef = createRef()

  useEffect(() => {
    const caregiver = getCaregiverFromCookie(store.dispatch)
    if (caregiver) {
      const { token } = caregiver
      const socket = io(`${config.ws_url}?token=${token}`)

      socket.on("connect", () => {
        socket.emit("ready for data", {})
      })
      socket.on("update", ({ message }) => {
        store.dispatch(setIncomingMessage(message))
      })
      socket.on("messageSeen", (data) => {
        store.dispatch(setLastMessageSeen(data))
      })
      socket.on("contact: signed", (data) => {
        const { sessionId } = data
        store.dispatch(setJournalized(sessionId))
      })
      return () => {
        socket.disconnect()
      }
    }
  }, [])

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalStorageListenerWrapper>
            <AppRoutes />
          </LocalStorageListenerWrapper>
          <IdleTimer
            ref={idleTimerRef}
            element={document}
            onIdle={() => store.dispatch(removeUserCredentials())}
            timeout={IDLE_TIMER}
          />
          <Alert />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  )
}

export default App
