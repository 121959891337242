import { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import {
  showAlert,
  addCalendarAvailability,
  editCalendarAvailability,
  removeCalendarAvailability,
} from "redux/actions"
import { useDispatch } from "react-redux"
import { createAvailability } from "api/createAvailability"
import { updateAvailability } from "api/updateAvailability"
import { deleteAvailability } from "api/deleteAvailability"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material"
import { Delete as DeleteIcon } from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import DatePicker from "components/DatePicker"
import Select from "components/Select"
import availabilityValidationSchema from "validation/availabilityValidation"
import addDays from "date-fns/addDays"
import addMonths from "date-fns/addMonths"
import parse from "date-fns/parse"
import format from "date-fns/format"
import moment from "moment"
import { availabilityType as availabilityTypes } from "utils/availabilityType"
import { availabilityUpdateType as updateType } from "utils/availabilityUpdateType"
// eslint-disable-next-line max-len
import { availabilityConfirmationDialogType as availabilityConfirmationDialogTypes } from "utils/availabilityConfirmationDialogType"
import { getTimeSlotsBetweenTimesWithDefinedInterval } from "utils/getTimeSlotsBetweenTimesWithDefinedInterval"
import AvailabilityConfirmationDialog from "./AvailabilityConfirmationDialog"

const useStyles = makeStyles((theme) => ({
  saveButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    opacity: 0.8,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      opacity: 1,
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  deleteButton: {
    color: theme.palette.error.main,
    float: "right",
    "& svg": {
      fontSize: "1.3rem",
    },
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
    },
  },
  dialogTitle: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexFlow: "column",
    },
  },
  dialogContent: {
    padding: theme.spacing(1, 3),
    overflow: "hidden",
    "& .MuiFormHelperText-root.Mui-error": {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  rowContainer: {
    display: "inline-flex",
    alignItems: "start",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
    },
    "& .MuiFormControl-root": {
      marginLeft: theme.spacing(1.25),
      width: "230px",
    },
  },
  "@global": {
    ".MuiDialogTitle-root": {
      textAlign: "center",
    },
    "@media only screen and (max-width: 690px)": {
      ".MuiDialog-paperWidthMd": {
        width: "97%",
        margin: "0",
      },
    },
  },
}))

const timeFormat = "HH:mm"
const resolver = (data) => {
  data.availabilityStartTime = data.availabilityStartTime
    ? parse(data.availabilityStartTime, timeFormat, new Date())
    : null
  data.availabilityEndTime = data.availabilityEndTime
    ? parse(data.availabilityEndTime, timeFormat, new Date())
    : null
  const { error, value } = availabilityValidationSchema(data)
  const responseValues = error ? {} : value
  const responseErrors = error
    ? error.details.reduce(
        (previous, currentError) => ({
          ...previous,
          [currentError.path[0]]: currentError,
        }),
        {}
      )
    : {}

  return {
    values: responseValues,
    errors: responseErrors,
  }
}

const startTimeSlots = getTimeSlotsBetweenTimesWithDefinedInterval(
  "00:00",
  "23:00",
  30
)
const endTimeSlots = getTimeSlotsBetweenTimesWithDefinedInterval(
  "00:30",
  "23:30",
  30
)

const AvailabilityDialog = ({ onClose, openDialog, userId, availability }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const availabilityId = availability?.id || null
  const availabilityStart = availability?.start || null
  const availabilityEnd = availability?.end || null
  const availabilityType = availability?.type || null
  const defaultValues = {
    availabilityDate: availabilityStart,
    availabilityStartTime: availabilityStart
      ? format(availabilityStart, timeFormat)
      : null,
    availabilityEndTime: availabilityEnd
      ? format(availabilityEnd, timeFormat)
      : null,
    availabilityType: availabilityType || availabilityTypes.Aldrig,
  }
  const { control, handleSubmit, formState, reset, getValues } = useForm({
    resolver,
    defaultValues,
  })
  const [
    openAvailabilityConfirmationDialog,
    setOpenAvailabilityConfirmationDialog,
  ] = useState(false)
  const [
    availabilityConfirmationDialogType,
    setAvailabilityConfirmationDialogType,
  ] = useState(null)
  const { isSubmitting, isDirty } = formState
  const isEditMode = !!availabilityId
  const isRecurring =
    availabilityType === availabilityTypes["Varje arbetsdag"] ||
    availabilityType === availabilityTypes["Varje vecka"]
  const nonRecurringAvailabilityUpdateType = updateType.ALL

  useEffect(() => {
    reset(defaultValues)
  }, [availability])

  const handleCloseDialog = () => {
    onClose()
    reset(defaultValues)
  }

  const handleErrorMessage = (error) => {
    const errorMessage = error?.response?.data?.message || "Något gick fel"
    dispatch(showAlert("error", errorMessage))
    const formValues = getValues()
    reset(formValues, {
      keepIsSubmitted: false,
    })
  }

  const handleDeleteAvailability = (availabilityUpdateType) => {
    deleteAvailability(userId, availabilityId, {
      start: availabilityStart,
      end: availabilityEnd,
      type: availabilityType,
      updateType: availabilityUpdateType,
    })
      .then(() => {
        dispatch(
          removeCalendarAvailability(
            availabilityId,
            availabilityStart,
            availabilityUpdateType
          )
        )
      })
      .catch((error) => {
        handleErrorMessage(error)
      })
      .finally(() => {
        setOpenAvailabilityConfirmationDialog(false)
        handleCloseDialog()
      })
  }

  const handleOpenUpdateConfirmationDialog = () => {
    setAvailabilityConfirmationDialogType(
      availabilityConfirmationDialogTypes.UPDATE
    )
    setOpenAvailabilityConfirmationDialog(true)
  }

  const onSubmit = (formData, availabilityUpdateType) => {
    const {
      availabilityDate,
      availabilityStartTime,
      availabilityEndTime,
      availabilityType: type,
    } = formData
    const isDaylightSavingTime = moment(availabilityDate).isDST()
    const start = new Date(
      availabilityDate.setHours(
        isDaylightSavingTime
          ? availabilityStartTime.getHours()
          : availabilityStartTime.getHours() - 1,
        availabilityStartTime.getMinutes(),
        availabilityStartTime.getSeconds()
      )
    )
    const end = new Date(
      availabilityDate.setHours(
        isDaylightSavingTime
          ? availabilityEndTime.getHours()
          : availabilityEndTime.getHours() - 1,
        availabilityEndTime.getMinutes(),
        availabilityEndTime.getSeconds()
      )
    )

    if (isEditMode) {
      return new Promise(() => {
        updateAvailability(userId, availabilityId, {
          start,
          end,
          type,
          updateType: availabilityUpdateType,
        })
          .then(({ data }) => {
            dispatch(editCalendarAvailability(data))
            handleCloseDialog()
          })
          .catch((error) => {
            handleErrorMessage(error)
          })
      })
    }
    return new Promise(() => {
      createAvailability(userId, { start, end, type })
        .then(({ data }) => {
          dispatch(addCalendarAvailability(data))
          handleCloseDialog()
        })
        .catch((error) => {
          handleErrorMessage(error)
        })
    })
  }

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={openDialog} onClose={onClose}>
        <DialogTitle className={classes.dialogTitle}>
          {isEditMode ? (
            <>
              Uppdatera tillgänglighet
              <Button
                className={classes.deleteButton}
                onClick={() => {
                  setAvailabilityConfirmationDialogType(
                    availabilityConfirmationDialogTypes.DELETE
                  )
                  setOpenAvailabilityConfirmationDialog(true)
                }}
              >
                <DeleteIcon />
                Radera
              </Button>
            </>
          ) : (
            "Lägga till tillgänglighet"
          )}
        </DialogTitle>
        <form
          onSubmit={handleSubmit((data) =>
            onSubmit(data, nonRecurringAvailabilityUpdateType)
          )}
        >
          <DialogContent className={classes.dialogContent}>
            <Box mt={3} />
            <Box className={classes.rowContainer}>
              <DatePicker
                disabled={isEditMode}
                name="availabilityDate"
                controller={control}
                label="Datum"
                minDate={addDays(new Date(), 1)}
                maxDate={addMonths(new Date(), 3)}
              />
            </Box>
            <Box mt={3} />
            <Box className={classes.rowContainer}>
              <Select
                name="availabilityStartTime"
                controller={control}
                label="Starttid"
                data={startTimeSlots}
              />
              <Box mt={3} />
              <Select
                name="availabilityEndTime"
                controller={control}
                label="Sluttid"
                data={endTimeSlots}
              />
            </Box>
            <Box mt={3} />
            <Box className={classes.rowContainer}>
              <Select
                name="availabilityType"
                data={Object.entries(availabilityTypes)}
                controller={control}
                label="Upprepa"
                labelId="repeat-availability"
                disabled={isEditMode}
                availabilityTypeSelect
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleCloseDialog()}
              className={classes.closeButton}
              color="default"
            >
              Stäng
            </Button>
            <Button
              disabled={isSubmitting || !isDirty}
              className={classes.saveButton}
              type={isRecurring ? "button" : "submit"}
              onClick={isRecurring ? handleOpenUpdateConfirmationDialog : null}
            >
              Spara
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <AvailabilityConfirmationDialog
        openDialog={openAvailabilityConfirmationDialog}
        onClose={() => setOpenAvailabilityConfirmationDialog(false)}
        onDeleteAvailability={(availabilityUpdateType) =>
          handleDeleteAvailability(availabilityUpdateType)
        }
        onUpdateAvailability={(availabilityUpdateType) => {
          setOpenAvailabilityConfirmationDialog(false)
          handleSubmit(onSubmit)(availabilityUpdateType)
        }}
        dialogType={availabilityConfirmationDialogType}
        isRecurringAvailability={isRecurring}
      />
    </>
  )
}

export default AvailabilityDialog
