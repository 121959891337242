import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Person as PersonIcon, Event as EventIcon } from "@mui/icons-material"
import format from "date-fns/format"
import { sv } from "date-fns/locale"
import isBefore from "date-fns/isBefore"

const useStyles = makeStyles((theme) => ({
  joinButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    opacity: 0.8,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
      opacity: 1,
    },
  },
  closeButton: {
    "&:hover": { backgroundColor: "rgba(0,0,0,0.1)" },
  },
  dialogTitle: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexFlow: "column",
    },
  },
  dialogContent: {
    padding: theme.spacing(1, 3),
    overflow: "hidden",
  },
  rowContainer: {
    width: "100%",
    display: "inline-flex",
    alignItems: "start",
    "& .MuiFormControl-root": {
      marginLeft: theme.spacing(1.25),
      width: "230px",
    },
    "& svg": {
      marginRight: "10px",
    },
  },
  link: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "90%",
  },
  "@global": {
    ".MuiDialogTitle-root": {
      textAlign: "center",
    },
    "@media only screen and (max-width: 690px)": {
      ".MuiDialog-paperWidthMd": {
        width: "97%",
        margin: "0",
      },
    },
  },
}))

const MeetingDialog = ({ onClose, openDialog, meeting, onJoinMeeting }) => {
  const classes = useStyles()
  const {
    start,
    end,
    title: patientFullName,
    resource: meetingHostUrl,
  } = meeting || {}
  const isPastMeeting = isBefore(end, new Date())

  return (
    <Dialog fullWidth maxWidth="sm" open={openDialog} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        Mötesinformation
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box mt={2} />
        <Box className={classes.rowContainer}>
          <EventIcon />
          <Typography>
            {start &&
              end &&
              `${format(start, "E, d MMMM", {
                locale: sv,
              })} ${format(start, "HH:mm")} - ${format(end, "HH:mm")}`}
          </Typography>
        </Box>
        <Box mt={2} />
        <Box className={classes.rowContainer}>
          <PersonIcon /> <Typography>{patientFullName}</Typography>
        </Box>
        <Box mt={2} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}
          className={classes.closeButton}
          color="default"
        >
          Stäng
        </Button>
        <Button
          disabled={isPastMeeting}
          className={classes.joinButton}
          onClick={() => onJoinMeeting(meetingHostUrl)}
        >
          Anslut
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MeetingDialog
