/* eslint-disable no-nested-ternary */
import React from "react"
import moment from "moment"
import { Typography, Button, useMediaQuery } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Table } from "components/Table"
import config from "config"
import { sessionType } from "utils/sessionType"
import { useTheme } from "@emotion/react"

const useStyles = makeStyles((theme) => ({
  tableCell: {
    color: theme.palette.black,
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  sortIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  emptyText: {
    color: theme.palette.black,
  },
  tableCellButton: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.8",
    },
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
    "& p": { fontSize: "12px" },
  },
  videoText: {
    textTransform: "uppercase",
    fontSize: "12px",
  },
}))

const getColumns = ({ classes, onOpenChatClick, isWidthSmDown }) => [
  {
    id: "patient",
    align: "left",
    sortable: true,
    label: "Patient",
    mobileTitle: true,
    formatBodyCell({ value }) {
      return (
        <Typography variant="body2" className={classes.tableCell}>
          {value}
        </Typography>
      )
    },
  },
  {
    id: "sessionNumber",
    sortable: false,
    align: "right",
    label: "Session",
    formatBodyCell({ value }) {
      return (
        <Typography variant="body2" className={classes.tableCell}>
          {value}
        </Typography>
      )
    },
  },
  {
    id: "createdAt",
    sortable: true,
    align: "right",
    tooltipTitle: "Start (journalföringsdatum)",
    label: "Start",
    formatBodyCell({ value }) {
      return (
        <Typography variant="body2" className={classes.tableCell}>
          {moment(value).format("D MMM")}
        </Typography>
      )
    },
  },
  {
    id: "endedAt",
    sortable: false,
    align: "right",
    label: "Slut",
    formatBodyCell({ value }) {
      return (
        <Typography variant="body2" className={classes.tableCell}>
          {moment(value).format("D MMM")}
        </Typography>
      )
    },
  },
  {
    id: "chatId",
    sortable: false,
    align: isWidthSmDown ? "right" : "center",
    label: "Besökstyp",
    formatBodyCell({ rowData }) {
      return rowData.sessionType === sessionType.CHAT ? (
        <Button
          onClick={() => onOpenChatClick(rowData)}
          className={classes.tableCellButton}
        >
          <Typography variant="body2">Se chatt</Typography>
        </Button>
      ) : (
        <Typography variant="body2" className={classes.videoText}>
          Video
        </Typography>
      )
    },
  },
  {
    id: "kaddio",
    sortable: false,
    align: "right",
    label: "Kaddio journal",
    formatBodyCell({ rowData }) {
      return (
        rowData.visitId && (
          <Button
            onClick={() =>
              window.open(
                `${config.kaddioUrl}/contacts/${rowData.visitId}`,
                "_blank"
              )
            }
            className={classes.tableCellButton}
          >
            <Typography variant="body2">Öppna journalanteckning</Typography>
          </Button>
        )
      )
    },
  },
]

export const SessionTable = ({
  data = [],
  orderBy,
  order,
  setOrder,
  setOrderBy,
  emptyText,
  handleOpenChat,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isWidthSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  const handleSortChange = (id) => {
    const isAsc = orderBy === id && order === "asc"

    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(id)
  }

  const columns = getColumns({
    classes,
    onOpenChatClick: handleOpenChat,
    isWidthSmDown,
  })

  return (
    <Table
      data={data}
      columns={columns}
      order={order}
      orderBy={orderBy}
      emptyText={emptyText}
      onSortChange={handleSortChange}
    />
  )
}
