import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Box,
  Hidden,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ButtonGroup,
  Button,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import TouchButton from "components/TouchButton"
import {
  ArrowBack as ArrowBackIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material/"
import config from "config"
import { fetchUserChats, fetchSessions } from "redux/actions"
import { SessionTableWrapper } from "./components/SessionsTableWrapper"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: "bold",
  },
  accordionTitle: {
    color: theme.palette.white,
    textDecoration: "none",
    fontWeight: "600",
  },
  accordion: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    border: "0px",
  },
  accordionDetails: {
    backgroundColor: theme.palette.lighter_gray,
    color: theme.palette.white,
  },
  icon: {
    color: theme.palette.white,
  },
}))

const Sessions = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    patients,
    sessions: { past: pastSessions },
    user,
  } = useSelector((store) => store)

  useEffect(() => {
    if (patients?.length === 0) {
      dispatch(fetchUserChats(user.userId))
    }
    dispatch(fetchSessions(user.userId))
  }, [])

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <Box mx={2} mt={2}>
          <TouchButton to="/">
            <ArrowBackIcon />
            <Box ml={1}>
              <Typography color="primary">Tillbaka till chatten</Typography>
            </Box>
          </TouchButton>
        </Box>
      </Hidden>
      <Box m={2}>
        <Box display="flex" justifyContent="flex-end">
          <ButtonGroup variant="outlined" color="primary">
            <Button href={config.kaddioUrl} target="_blank">
              Kaddio
            </Button>
            <Button href={config.guideUrl} target="_blank">
              Psykologguide
            </Button>
          </ButtonGroup>
        </Box>
        <Box ml={2} mt={2}>
          <Typography color="primary" variant="h6" className={classes.title}>
            Mina slutförda sessioner
          </Typography>
        </Box>
        <Accordion
          variant="outlined"
          className={classes.accordion}
          defaultExpanded
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
          >
            <Typography variant="body1" className={classes.accordionTitle}>
              Osignerade sessioner (skapas automatiskt i Kaddio)
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <SessionTableWrapper
              data={pastSessions}
              patients={patients}
              emptyText="Det finns inga journalanteckningar."
            />
          </AccordionDetails>
        </Accordion>
        <Accordion variant="outlined" className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
          >
            <Typography variant="body1" className={classes.accordionTitle}>
              Signerade sessioner
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <SessionTableWrapper
              journalized
              data={pastSessions}
              patients={patients}
              emptyText="Det finns inga besök som ska journalföras."
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  )
}

export default Sessions
