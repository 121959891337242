const materials = {
  models: [
    {
      description: "Ambivalenskorset",
      url: "https://dinpsykolog.se/ambivalenskorset/",
    },
    {
      description: "Biopsykosocial modell",
      url: "https://dinpsykolog.se/biopsykosocial-modell/",
    },
    {
      description: "KBT (Känsla, tanke, beteende, kropp)",
      url: "https://dinpsykolog.se/kbt/",
    },
    {
      description: "Krav-kontroll-stöd-modell",
      url: "https://dinpsykolog.se/krav-kontroll-stod-modell/",
    },
    {
      description: "Livskompassen",
      url: "https://dinpsykolog.se/livskompassen/",
    },
    {
      description: "Modell för depression - kort",
      url: "https://dinpsykolog.se/depression-kort-modell/",
    },
    {
      description: "Modell för depression - lång",
      url: "https://dinpsykolog.se/depression-lang-modell/",
    },
    {
      description: "Modell för panikcirkel",
      url: "https://dinpsykolog.se/modell-for-panikcirkel/",
    },
    {
      description: "Modell för social fobi",
      url: "https://dinpsykolog.se/modell-for-social-fobi/",
    },
    {
      description: "Modell för tvång",
      url: "https://dinpsykolog.se/modell-for-tvang/",
    },
    {
      description: "Ångestkurva",
      url: "https://dinpsykolog.se/angestkurva/",
    },
  ],
  psychoeducations: [
    {
      description: "Om depression",
      url: "https://dinpsykolog.se/om-depression/",
    },
    {
      description: "Om dödsångest",
      url: "https://dinpsykolog.se/om-dodsangest/",
    },
    {
      description: "Om generell ångest",
      url: "https://dinpsykolog.se/om-generell-angest/",
    },
    {
      description: "Om hälsoångest",
      url: "https://dinpsykolog.se/vad-ar-halsoangest/",
    },
    {
      description: "Om insomni",
      url: "https://dinpsykolog.se/om-insomni/",
    },
    {
      description: "Om känslor",
      url: "https://dinpsykolog.se/om-kanslor/",
    },
    {
      description: "Om panikångest",
      url: "https://dinpsykolog.se/om-panikangest/",
    },
    {
      description: "Om social ångest",
      url: "https://dinpsykolog.se/om-social-angest/",
    },
    {
      description: "Om sorg",
      url: "https://dinpsykolog.se/psykoedukativa-texter-om-sorg/",
    },
    {
      description: "Om specifika fobier",
      url: "https://dinpsykolog.se/om-specifika-fobier/",
    },
    {
      description: "Om stress",
      url: "https://dinpsykolog.se/om-stress/",
    },
    {
      description: "Om tvång",
      url: "https://dinpsykolog.se/om-tvang/",
    },
    {
      description: "Om ångest",
      url: "https://dinpsykolog.se/om-angest/",
    },
  ],
  treatmentPrograms: {
    emotionallyFocusedPrograms: [
      {
        description: "Känslofokuserat behandlingsprogram intro 1",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-intro-1/",
      },
      {
        description: "Känslofokuserat behandlingsprogram intro 2",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-intro-2/",
      },
      {
        description: "Känslofokuserat behandlingsprogram modul 1",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-1/",
      },
      {
        description: "Känslofokuserat behandlingsprogram modul 2",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-2/",
      },
      {
        description: "Känslofokuserat behandlingsprogram modul 3",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-3/",
      },
      {
        description: "Känslofokuserat behandlingsprogram modul 4",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-4/",
      },
      {
        description: "Känslofokuserat behandlingsprogram modul 5",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-5/",
      },
      {
        description: "Känslofokuserat behandlingsprogram modul 6",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-6/",
      },
      {
        description: "Känslofokuserat behandlingsprogram modul 7",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-7/",
      },
      {
        description: "Känslofokuserat behandlingsprogram modul 8",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-8/",
      },
      {
        description: "Känslofokuserat behandlingsprogram modul 9",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-9/",
      },
      {
        description: "Känslofokuserat behandlingsprogram modul 10",
        url: "https://dinpsykolog.se/kanslofokuserat-behandlingsprogram-modul-10/",
      },
    ],
    stressProgram: [
      {
        description: "Intro - Stress",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress/",
      },
      {
        description: "Behandlingsprogram för stress - Modul 1",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-1",
      },
      {
        description: "Behandlingsprogram för stress - Modul 2",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-2/",
      },
      {
        description: "Behandlingsprogram för stress - Modul 3",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-3/",
      },
      {
        description: "Behandlingsprogram för stress - Modul 4",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-4/",
      },
      {
        description: "Behandlingsprogram för stress - Modul 5",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-5/",
      },
      {
        description: "Behandlingsprogram för stress - Modul 6",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-6/",
      },
      {
        description: "Behandlingsprogram för stress - Modul 7",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-7/",
      },
      {
        description: "Behandlingsprogram för stress - Modul 8",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-8/",
      },
      {
        description: "Behandlingsprogram för stress - Modul 9",
        url: "https://dinpsykolog.se/behandlingsprogram-for-stress-modul-9/",
      },
    ],
    sleepingProblemPrograms: [
      {
        description: "Intro - Sömnproblem",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-intro/",
      },
      {
        description: "Behandlingsprogram för sömnproblem - Modul 1",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-modul-1/",
      },
      {
        description: "Behandlingsprogram för sömnproblem - Modul 2",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-modul-2/",
      },
      {
        description: "Behandlingsprogram för sömnproblem - Modul 3",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-modul-3/",
      },
      {
        description: "Behandlingsprogram för sömnproblem - Modul 4",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-modul-4/",
      },
      {
        description: "Behandlingsprogram för sömnproblem - Modul 5",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-modul-5/",
      },
      {
        description: "Behandlingsprogram för sömnproblem - Modul 6",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-modul-6/",
      },
      {
        description: "Behandlingsprogram för sömnproblem - Modul 7",
        url: "https://dinpsykolog.se/behandlingsprogram-for-somnproblem-modul-7/",
      },
    ],
  },
  cbtWorksheets: [
    {
      description: "Aktivitetsschema",
      url: "https://dinpsykolog.se/aktivitetsschema/",
    },
    {
      description: "Andas i fyrkant",
      url: "https://dinpsykolog.se/andas-i-fyrkant/",
    },
    {
      description: "Andningsankaret",
      url: "https://dinpsykolog.se/andningsankaret/",
    },
    {
      description: "Behandlingsmål",
      url: "https://dinpsykolog.se/behandlingsmal/",
    },
    {
      description: "Beteendeanalys (SORK)",
      url: "https://dinpsykolog.se/beteendeanalys-sork/",
    },
    {
      description: "Jag-budskap",
      url: "https://dinpsykolog.se/jag-budskap/",
    },
    {
      description: "Kroppsscanning",
      url: "https://dinpsykolog.se/kroppsscanning/",
    },
    {
      description: "Möt känslan i kroppen",
      url: "https://dinpsykolog.se/mot-kanslan-i-kroppen/",
    },
    {
      description: "Orosdagbok",
      url: "https://dinpsykolog.se/orosdagbok/",
    },
    {
      description: "Orosstund",
      url: "https://dinpsykolog.se/orosstund/",
    },
    {
      description: "Positiva antaganden om oro",
      url: "https://dinpsykolog.se/positiva-antaganden-om-oro/",
    },
    {
      description: "Problemlösning",
      url: "https://dinpsykolog.se/problemlosning/",
    },
    {
      description: "Progressiv avslappning",
      url: "https://dinpsykolog.se/progressiv-avslappning/",
    },
    {
      description: "Registrera automatiska tankar",
      url: "https://dinpsykolog.se/registrera-automatiska-tankar/",
    },
    {
      description: "Självmedkänsla",
      url: "https://dinpsykolog.se/sjalvmedkansla/",
    },
    {
      description: "Sömndagbok",
      url: "https://dinpsykolog.se/somndagbok/",
    },
    {
      description: "Tolerans för osäkerhet",
      url: "https://dinpsykolog.se/tolerans-for-osakerhet/",
    },
    {
      description: "Undersök automatiska tankar",
      url: "https://dinpsykolog.se/undersok-automatiska-tankar/",
    },
    {
      description: "Vidmakthållandeplan",
      url: "https://dinpsykolog.se/kbt-arbetsblad-vidmakthallandeplan/",
    },
    {
      description: "Ångesthierarki",
      url: "https://dinpsykolog.se/angesthierarki/",
    },
    {
      description: "Överskott och underskott",
      url: "https://dinpsykolog.se/overskott-och-underskott/",
    },
  ],
}

export default materials
