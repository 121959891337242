import { useState, useRef, useEffect } from "react"
import moment from "moment"
import materials from "utils/materials"
import {
  Box,
  Typography,
  Card,
  CircularProgress,
  IconButton,
} from "@mui/material"
import { makeStyles, useTheme } from "@mui/styles"
import DefaultAvatar from "components/DefaultAvatar"
import AnonymousModeWrapper from "components/AnonymousModeWrapper"
import { entryType } from "utils/entryType"
import Linkify from "react-linkify"
import {
  Close as CloseIcon,
  Visibility as VisibilityIcon,
  Launch as LaunchIcon,
} from "@mui/icons-material/"
import { dataType as messageType } from "utils/dataType"

const IMAGE_SIZE = 36

const useStyles = makeStyles((theme) => ({
  root: ({ caregiverSentMessage }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: caregiverSentMessage ? "flex-end" : "flex-start",
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingTop: caregiverSentMessage ? theme.spacing(2) : theme.spacing(5),
  }),
  systemMessageRoot: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 0",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderImageSlice: 1,
    borderImageSource: `
      linear-gradient(0.25turn, transparent, ${theme.palette.gray}, transparent)
    `,
    margin: "20px 0 10px 0",
  },
  container: {
    maxWidth: "85%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "90%",
    },
  },
  name: {
    position: "absolute",
    top: theme.spacing(2),
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
  },
  systemMessageTitle: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 14,
    color: theme.palette.dark_gray,
    fontWeight: theme.typography.fontWeightMedium,
  },
  bubble: ({ caregiverSentMessage }) => ({
    padding: theme.spacing(1),
    borderRadius: 6,
    wordBreak: "break-word",
    backgroundColor: caregiverSentMessage
      ? theme.palette.primary.main
      : theme.palette.light_gray,
  }),
  systemMessageBubble: {
    padding: theme.spacing(1),
    wordBreak: "break-word",
  },
  systemMessageDate: {
    textTransform: "capitalize",
  },
  text: ({ caregiverSentMessage, typeOfMsg }) => ({
    color:
      !caregiverSentMessage || typeOfMsg === entryType.SYSTEM
        ? theme.palette.black
        : theme.palette.white,
    textAlign:
      typeOfMsg === entryType.SYSTEM_CAREGIVER || typeOfMsg === entryType.SYSTEM
        ? "center"
        : "left",
    display: "block",
    whiteSpace: "pre-wrap",
  }),
  messageInfo: ({ caregiverSentMessage }) => ({
    position: "absolute",
    right: caregiverSentMessage ? theme.spacing(2) : null,
    left: caregiverSentMessage
      ? null
      : Number(theme.spacing(2).slice(0, -2)) + IMAGE_SIZE,
    display: "flex",
    flexDirection: "row",
    justifyContent: caregiverSentMessage ? "flex-end" : "flex-start",
    "& span:last-child": {
      marginLeft: theme.spacing(1),
    },
  }),
  image: {
    marginRight: theme.spacing(1),
  },
  seenText: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.dark_gray,
    fontWeight: theme.typography.fontWeightMedium,
  },
  linkContainer: ({ typeOfMsg }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: typeOfMsg === entryType.SYSTEM ? "center" : "inherit",
  }),
  link: {
    display: "flex !important",
    "& svg": {
      top: "5px",
    },
  },
  externalLink: {
    fontSize: "15px",
    marginRight: "3px",
    position: "relative",
    top: "3px",
  },
  card: {
    marginTop: "5px",
    position: "relative",
    boxShadow: "none",
  },
  materialIconButton: ({ colorBasedOnSenderAndMessageType }) => ({
    marginLeft: "15px",
    padding: "6px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    "& svg": {
      color: colorBasedOnSenderAndMessageType,
    },
  }),
  closeButton: {
    marginRight: "-2px",
  },
  previewButton: ({ colorBasedOnSenderAndMessageType }) => ({
    "& svg": {
      fontSize: "24px",
      padding: "1px 2px",
      border: `2px solid ${colorBasedOnSenderAndMessageType}`,
      borderTop: `5px solid ${colorBasedOnSenderAndMessageType}`,
      borderRadius: "3px",
    },
  }),
  wide: {
    width: "90vw",
    maxWidth: "100%",
  },
  inlineLink: {
    display: "inline !important",
  },
  topPreviewContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "-2px",
  },
  iframe: {
    minHeight: "50vh",
    display: "block",
    width: "100%",
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  video: {
    height: "250px",
    width: "250px",
    [theme.breakpoints.down("sm")]: {
      height: "210px",
      width: "210px",
    },
  },
}))

const ChatBubble = ({
  message,
  userId,
  patient,
  isLastMessage,
  isFirstUserMessage,
  firstUserMessageRef,
}) => {
  const {
    data,
    createdAt,
    fromUserId,
    seen,
    type: typeOfMsg,
    dataType,
  } = message
  const { firstName, lastName, avatarUrl } = patient.information
  const caregiverSentMessage = userId === fromUserId
  const theme = useTheme()
  const colorBasedOnSenderAndMessageType =
    !caregiverSentMessage || typeOfMsg === entryType.SYSTEM
      ? theme.palette.primary.main
      : theme.palette.white
  const classes = useStyles({
    caregiverSentMessage,
    typeOfMsg,
    colorBasedOnSenderAndMessageType,
  })
  const [showPreview, setShowPreview] = useState(true)
  const [previewBtnClicked, setPreviewBtnClicked] = useState(false)
  const linkContainer = useRef(null)
  const [iframeLoaded, setIframeLoaded] = useState(false)

  useEffect(() => {
    if (showPreview && previewBtnClicked) {
      linkContainer.current.scrollIntoView({ block: "center" })
    }
  }, [showPreview, previewBtnClicked])

  const componentDecorator = (href, text, key) => {
    const materialUrls = [
      ...materials.models.map((model) => model.url),
      ...materials.psychoeducations.map(
        (psychoeducation) => psychoeducation.url
      ),
      ...materials.treatmentPrograms.emotionallyFocusedPrograms.map(
        (emotionallyFocusedProgram) => emotionallyFocusedProgram.url
      ),
      ...materials.treatmentPrograms.stressProgram.map(
        (stressProgram) => stressProgram.url
      ),
      ...materials.treatmentPrograms.sleepingProblemPrograms.map(
        (sleepingProblemProgram) => sleepingProblemProgram.url
      ),
      ...materials.cbtWorksheets.map((cbtWorksheet) => cbtWorksheet.url),
    ]
    if (
      materialUrls.includes(href) ||
      href.includes("kaddio.com/form-answer-client")
    ) {
      let linkDescription
      if (materialUrls.includes(href)) {
        const materialIndex = materialUrls.indexOf(href)
        const materialDescriptions = [
          ...materials.models.map((model) => model.description),
          ...materials.psychoeducations.map(
            (psychoeducation) => psychoeducation.description
          ),
          ...materials.treatmentPrograms.emotionallyFocusedPrograms.map(
            (emotionallyFocusedProgram) => emotionallyFocusedProgram.description
          ),
          ...materials.treatmentPrograms.stressProgram.map(
            (stressProgram) => stressProgram.description
          ),
          ...materials.treatmentPrograms.sleepingProblemPrograms.map(
            (sleepingProblemProgram) => sleepingProblemProgram.description
          ),
          ...materials.cbtWorksheets.map(
            (cbtWorksheet) => cbtWorksheet.description
          ),
        ]
        linkDescription = materialDescriptions[materialIndex]
      } else {
        const hashtagIndex = href.indexOf("#")
        const hashtagText = href.substring(hashtagIndex + 1)
        linkDescription = hashtagText.replace(/&/g, " ")
      }
      const materialPreviewIcon = showPreview ? (
        <CloseIcon />
      ) : (
        <VisibilityIcon />
      )
      return (
        <div
          key={key}
          ref={linkContainer}
          className={`${showPreview ? classes.wide : classes.linkContainer}`}
        >
          <div className={classes.topPreviewContainer}>
            <a
              href={href}
              target="_blank"
              rel="noreferrer"
              className={`${classes.text} ${classes.link}`}
            >
              <LaunchIcon className={classes.externalLink} />
              {linkDescription}
            </a>
            <IconButton
              onClick={() => {
                setIframeLoaded(showPreview)
                setShowPreview(!showPreview)
                setPreviewBtnClicked(true)
              }}
              className={`${classes.materialIconButton} ${
                showPreview ? classes.closeButton : classes.previewButton
              }`}
            >
              {materialPreviewIcon}
            </IconButton>
          </div>
          {showPreview && (
            <Card className={classes.card}>
              <>
                <iframe
                  title="Materials"
                  src={`${href}#gdpr-accept-cookies`}
                  onLoad={() => setIframeLoaded(true)}
                  className={classes.iframe}
                />
                {!iframeLoaded && (
                  <Box className={classes.loader}>
                    <CircularProgress size={50} color="primary" />
                  </Box>
                )}
              </>
            </Card>
          )}
        </div>
      )
    }

    return (
      <a
        href={href}
        key={key}
        target="_blank"
        rel="noreferrer"
        className={`${classes.text} ${classes.inlineLink}`}
      >
        {text}
      </a>
    )
  }

  const renderSentText = () => (
    <Typography className={classes.seenText} component="span" variant="caption">
      {moment(createdAt).format("HH:mm")}
    </Typography>
  )

  const renderCaregiverSystemMessage = () => (
    <Box className={classes.systemMessageRoot}>
      <Box className={classes.container}>
        <Typography className={classes.systemMessageTitle} component="span">
          Din Psykolog
        </Typography>
        <Box className={classes.systemMessageBubble}>
          <Linkify componentDecorator={componentDecorator}>
            <Typography className={classes.text} component="span">
              {data}
            </Typography>
          </Linkify>
        </Box>
        <Box>
          <Typography
            className={classes.seenText}
            component="span"
            variant="caption"
          >
            <span className={classes.systemMessageDate}>
              {moment(createdAt).format("MMM DD, HH:mm")}
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  )

  const renderTextOrVideo = () => (
    <Linkify componentDecorator={componentDecorator}>
      {dataType === messageType.TEXT && (
        <Typography
          className={classes.text}
          component="span"
          ref={isFirstUserMessage ? firstUserMessageRef : null}
        >
          <AnonymousModeWrapper>{data}</AnonymousModeWrapper>
        </Typography>
      )}
      {dataType === messageType.VIDEO && (
        <AnonymousModeWrapper>
          <video
            src={data}
            title="video"
            preload="none"
            poster={data
              .replace("videos/", "videos/thumb_")
              .replace("mp4", "jpg")}
            className={classes.video}
            controls
          >
            <track kind="captions" />
          </video>
        </AnonymousModeWrapper>
      )}
    </Linkify>
  )

  const renderPatientOrCaregiverMessage = () => (
    <Box className={classes.root}>
      {!caregiverSentMessage && (
        <Box className={classes.image}>
          <AnonymousModeWrapper>
            <DefaultAvatar
              src={avatarUrl}
              size={IMAGE_SIZE}
              name={`${firstName} ${lastName}`}
            />
          </AnonymousModeWrapper>
        </Box>
      )}
      <Box className={classes.container}>
        {!caregiverSentMessage && (
          <Typography className={classes.name} component="span">
            <AnonymousModeWrapper>
              {firstName} {lastName}
            </AnonymousModeWrapper>
          </Typography>
        )}
        <Box className={classes.bubble}>{renderTextOrVideo()}</Box>
        <Box className={classes.messageInfo}>
          {renderSentText()}
          {seen && isLastMessage && (
            <Typography
              className={classes.seenText}
              component="span"
              variant="caption"
            >
              Läst av patient
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )

  const renderBubble = () => {
    switch (typeOfMsg) {
      case entryType.SYSTEM_PATIENT:
        return null
      case entryType.SYSTEM_CAREGIVER:
      case entryType.SYSTEM:
        return renderCaregiverSystemMessage()
      default:
        return renderPatientOrCaregiverMessage()
    }
  }

  return renderBubble()
}

export default ChatBubble
