import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
  fetchUserChats,
  fetchChatEntries,
  resetActiveChat,
  fetchKaddioForms,
  fetchKaddioCaregiverUserId,
} from "redux/actions"
import {
  Box,
  Hidden,
  CircularProgress,
  Typography,
  Button,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import SideBar from "pages/chat/components/SideBar"
import ChatContainer from "pages/chat/components/ChatContainer"
import ChatBar from "pages/chat/components/ChatBar"
import PatientInformation from "pages/chat/components/PatientInformation"
import isEmpty from "lodash.isempty"
import {
  SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
  ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material/"
import CameraDrawer from "./components/CameraDrawer"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  noPhone: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    textAlign: "center",
  },
  content: {
    boxSizing: "border-box",
    flex: 2,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      borderRight: `0.5px solid ${theme.palette.gray}`,
      borderBottom: `0.5px solid ${theme.palette.gray}`,
    },
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
  },
  errorButton: {
    marginTop: theme.spacing(2),
  },
  icon: {
    height: 200,
    width: 200,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      height: 150,
      width: 150,
    },
  },
  header: {
    textAlign: "center",
    fontSize: "3rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.125rem",
    },
  },
  text: {
    textAlign: "center",
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
  },
}))

const Chat = ({
  dispatch,
  user,
  chats,
  kaddioCaregiverUserId,
  kaddioForms,
}) => {
  const classes = useStyles()
  const {
    loading,
    error,
    instances,
    activeChat,
    patient: {
      information: { userId: patientId },
    },
    switchChatLoading,
  } = chats
  const { userId, socialSecurity: caregiverSocialSecurity } = user
  const [isCameraOpen, setCameraOpen] = useState(false)

  useEffect(() => {
    if (!!activeChat) {
      dispatch(fetchChatEntries(activeChat, patientId))
    }
  }, [activeChat, dispatch, patientId])

  useEffect(() => {
    dispatch(fetchUserChats(userId))
    if (!kaddioForms) {
      dispatch(fetchKaddioForms())
    }
    if (!kaddioCaregiverUserId) {
      dispatch(fetchKaddioCaregiverUserId(caregiverSocialSecurity))
    }

    // disables warning about missing dependency for userId & caregiverSocialSecurity
    // eslint-disable-next-line
  }, [dispatch]);

  const renderLoading = () => (
    <Box className={classes.loading}>
      <CircularProgress size={50} color="primary" />
    </Box>
  )

  if (loading) {
    return renderLoading()
  }

  if (error) {
    return (
      <Box className={classes.loading}>
        <ErrorOutlineIcon color="primary" className={classes.icon} />
        <Typography variant="h4">Något gick fel!</Typography>
        <Button
          variant="contained"
          onClick={() => dispatch(fetchUserChats(userId))}
          className={classes.errorButton}
        >
          Försök igen
        </Button>
      </Box>
    )
  }

  if (isEmpty(instances)) {
    return (
      <Box className={classes.loading}>
        <SentimentVeryDissatisfiedIcon
          color="primary"
          className={classes.icon}
        />
        <Typography className={classes.header}>
          Inga patienter hittade
        </Typography>
        <Typography className={classes.text}>
          Du har för tillfället inga patienter
        </Typography>
      </Box>
    )
  }

  const onBackClick = () => {
    dispatch(resetActiveChat())
  }

  const renderChat = () => (
    <Box className={classes.content}>
      <PatientInformation onBackClick={onBackClick} />
      {switchChatLoading ? (
        renderLoading()
      ) : (
        <ChatContainer active={!!activeChat} />
      )}
      {isCameraOpen && (
        <CameraDrawer
          isCameraOpen={isCameraOpen}
          setCameraOpen={setCameraOpen}
        />
      )}

      <ChatBar setCameraOpen={setCameraOpen} />
    </Box>
  )

  const renderSideBar = () => <SideBar chats={instances} />

  return (
    <Box className={classes.root}>
      <Hidden smDown>
        {renderSideBar()}
        {renderChat()}
      </Hidden>
      <Hidden smUp>{!!activeChat ? renderChat() : renderSideBar()}</Hidden>
    </Box>
  )
}

const mapStateToProps = ({
  user,
  chats,
  kaddioCaregiverUserId,
  kaddioForms,
}) => ({ user, chats, kaddioCaregiverUserId, kaddioForms })

export default connect(mapStateToProps)(Chat)
