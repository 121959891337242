/* eslint-disable no-nested-ternary */
import React, { useState } from "react"

import ChatPreviewDialog from "pages/chat/components/ChatPreviewDialog"
import { SessionTable } from "./SessionsTable"

export const SessionTableWrapper = ({
  journalized = false,
  data = [],
  patients = [],
  emptyText = "",
}) => {
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("createdAt")
  const [openChatPreviewDialog, setOpenChatPreviewDialog] = useState(false)
  const [chatPreviewSession, setChatPreviewSession] = useState(null)
  const getPatient = (userId) => patients.find((el) => el.userId === userId)

  const getPatientInfo = (userId, includeAllData) => {
    const patient = getPatient(userId)

    if (patient) {
      if (includeAllData) {
        const patientAllData = {}
        patientAllData.information = patient
        return patientAllData
      }
      return `${patient.firstName} ${patient.lastName}, ${patient.socialSecurity}`
    }
    return userId
  }

  const compareElements = (a, b) => {
    let x = ""
    let y = ""
    switch (orderBy) {
      case "createdAt":
        x = a.createdAt
        y = b.createdAt
        break
      case "patient":
      default:
        x = getPatientInfo(a?.userId, false).toLowerCase()
        y = getPatientInfo(b?.userId, false).toLowerCase()
        break
    }
    if (x === y) {
      x = a.sessionNumber
      y = b.sessionNumber
    }
    if (order === "desc") {
      return y < x ? 1 : -1
    }
    return x < y ? 1 : -1
  }

  const handleOpenChat = (session) => {
    setChatPreviewSession(session)
    setOpenChatPreviewDialog(true)
  }

  const handleCloseChat = () => {
    setOpenChatPreviewDialog(false)
  }

  const getData = () =>
    data.length
      ? data
          .sort(compareElements)
          .filter((session) =>
            journalized ? session.journalized : !session.journalized
          )
          .map((session) => ({
            ...session,
            patient: getPatientInfo(session.userId, false),
          }))
      : []

  return (
    <>
      <SessionTable
        data={getData()}
        order={order}
        orderBy={orderBy}
        emptyText={emptyText}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        handleOpenChat={handleOpenChat}
      />
      {chatPreviewSession?.userId && (
        <ChatPreviewDialog
          onClose={handleCloseChat}
          open={openChatPreviewDialog}
          session={chatPreviewSession}
          patient={getPatientInfo(chatPreviewSession.userId, true)}
        />
      )}
    </>
  )
}
