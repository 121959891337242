import React, { useState, useEffect } from "react"
import isAfter from "date-fns/isAfter"
import packageJson from "../package.json"

const checkIfOldBuildDate = (
  latestBuildDateInMilliseconds,
  currentBuildDateInMilliseconds
) => {
  const latestBuildDate = new Date(latestBuildDateInMilliseconds)
  const currentBuildDate = new Date(currentBuildDateInMilliseconds)

  return isAfter(latestBuildDate, currentBuildDate)
}

const deleteCacheAndReload = async () => {
  try {
    if (window?.caches) {
      // Delete cache and reload the page
      const { caches } = window
      const cacheNames = await caches.keys()
      for (const cacheName of cacheNames) {
        await caches.delete(cacheName)
      }
      window.location.reload()
    }
  } catch (error) {
    console.error("Error while deleting cache: ", error)
  }
}

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false)

    useEffect(() => {
      fetch("/meta.json", {
        headers: {
          "Cache-Control": "no-store",
          Pragma: "no-cache",
          Expires: 0,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((meta) => {
          const latestBuildDateInMilliseconds = meta.buildDate
          const currentBuildDateInMilliseconds = packageJson.buildDate

          const shouldForceRefresh = checkIfOldBuildDate(
            latestBuildDateInMilliseconds,
            currentBuildDateInMilliseconds
          )
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false)
            deleteCacheAndReload()
          } else {
            setIsLatestBuildDate(true)
          }
        })
    }, [])

    return isLatestBuildDate ? <Component {...props} /> : null
  }

  return ClearCacheComponent
}

export default withClearCache
