import { useEffect, useRef, useState } from "react"
import { Popover, DialogTitle, DialogContent, Button } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { TreeView, TreeItem } from "@mui/lab"
import { connect } from "react-redux"
import { fetchKaddioPatientUserId } from "redux/actions"
import {
  ChevronRight as ChevronRightIcon,
  ExpandMore as ExpandMoreIcon,
  Link as LinkIcon,
  AttachFile as AttachFileIcon,
  VideoCall,
} from "@mui/icons-material/"
import materials from "utils/materials"
import VideoMeetingModal from "components/VideoMeetingModal"
import { uploadFile } from "../../../redux/actions"
import MaterialPreviewDialog from "./MaterialPreviewDialog"

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
  buttonFile: {
    marginLeft: "7px",
    borderRadius: "0",
    width: "286px",
    fontWeight: "400",
    lineHeight: "24px",
    fontSize: "16px",
    textTransform: "none",
    justifyContent: "start",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    color: theme.palette.black,
  },
  content: {
    width: "300px",
    height: "230px",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "8px 0 8px 7px",
    "& ul": { overflow: "hidden", height: "auto" },
  },
  treeItem: {
    paddingLeft: "5px",
    "&:hover": {
      backgroundColor: "rgba(53,115,118,0.3)",
    },
  },
  "@global": {
    "#button-file": {
      display: "none",
    },
    ".MuiButton-startIcon": {
      marginRight: "4px",
    },
    ".MuiTreeView-root": {
      paddingLeft: "7px",
    },
    ".MuiTreeItem-label": {
      padding: "10px 0 10px 9px",
    },
    ".MuiPaper-root .MuiAccordionSummary-content": {
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        margin: "5px 0",
      },
    },
  },
}))

const MaterialPicker = ({
  dispatch,
  onClose,
  selectedMaterial,
  openPicker,
  anchorEl,
  kaddioForms,
  onNewFileUploaded,
  chatId,
  patientSocialSecurity,
}) => {
  const [isVideoModalOpen, setVideoModalOpen] = useState(false)
  const [materialPreviewDialogItem, setMaterialPreviewDialogItem] = useState({})
  const [openMaterialPreviewDialog, setOpenMaterialPreviewDialog] =
    useState(false)

  const handleCloseMaterialPicker = () => {
    onClose(selectedMaterial)
  }

  const handleNewFileUploaded = (file) => {
    dispatch(uploadFile(file)).then((url) => {
      onClose()
      if (url) onNewFileUploaded(url)
    })
  }

  const handleMaterialItemClick = (material) => {
    setMaterialPreviewDialogItem(material)
    setOpenMaterialPreviewDialog(true)
  }

  const handleKaddioFormClick = (formId, formName, formUrl) => {
    dispatch(fetchKaddioPatientUserId(patientSocialSecurity))
    setMaterialPreviewDialogItem({
      id: formId,
      description: formName,
      url: formUrl,
    })
    setOpenMaterialPreviewDialog(true)
  }

  const handleCloseMaterialPreviewDialog = () => {
    setOpenMaterialPreviewDialog(false)
    setMaterialPreviewDialogItem({})
  }

  const handleSendMaterial = () => {
    onClose(materialPreviewDialogItem)
    handleCloseMaterialPreviewDialog()
  }

  const kaddioFormularRef = useRef(null)
  const [isKaddioFormularOpen, setKaddioFormularOpen] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isKaddioFormularOpen) {
        kaddioFormularRef.current.scrollIntoView({ behavior: "smooth" })
      }
    }, 100)

    return () => clearTimeout(timeout)
  }, [isKaddioFormularOpen])

  const classes = useStyles()
  return (
    <>
      {isVideoModalOpen && (
        <VideoMeetingModal
          open={isVideoModalOpen}
          chatId={chatId}
          setVideoModalOpen={setVideoModalOpen}
        />
      )}
      <Popover
        open={openPicker}
        onClose={handleCloseMaterialPicker}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <DialogTitle className={classes.title}>Skicka material</DialogTitle>
        <DialogContent className={classes.content}>
          <label htmlFor="button-file">
            <input
              accept="image/*,.pdf,.xlsx,.docx"
              onInput={({
                target: {
                  files: [file],
                },
              }) => handleNewFileUploaded(file)}
              id="button-file"
              type="file"
            />
            <Button
              variant="text"
              size="large"
              className={classes.buttonFile}
              component="div"
              startIcon={<AttachFileIcon />}
            >
              Skicka fil
            </Button>
          </label>
          <Button
            variant="text"
            size="large"
            className={classes.buttonFile}
            component="div"
            startIcon={<VideoCall />}
            onClick={() => setVideoModalOpen(true)}
          >
            Create Instant Meeting
          </Button>
          <TreeView
            aria-label="materials menu"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
          >
            {materials.models.length > 0 && (
              <TreeItem nodeId="1" label="Modeller">
                {materials.models.map((model, index) => (
                  <TreeItem
                    onClick={() => handleMaterialItemClick(model)}
                    key={index}
                    className={classes.treeItem}
                    label={model.description}
                    endIcon={<LinkIcon />}
                  />
                ))}
              </TreeItem>
            )}
            {materials.psychoeducations.length > 0 && (
              <TreeItem nodeId="2" label="Psykoedukation">
                {materials.psychoeducations.map((psychoeducation, index) => (
                  <TreeItem
                    onClick={() => handleMaterialItemClick(psychoeducation)}
                    key={index}
                    className={classes.treeItem}
                    label={psychoeducation.description}
                    endIcon={<LinkIcon />}
                  />
                ))}
              </TreeItem>
            )}
            {Object.values(materials.treatmentPrograms).some(
              (treatmentProgram) => treatmentProgram.length > 0
            ) && (
              <TreeItem nodeId="3" label="Behandlingsprogram">
                {materials.treatmentPrograms.emotionallyFocusedPrograms.length >
                  0 && (
                  <TreeItem nodeId="4" label="Känslofokuserat">
                    {materials.treatmentPrograms.emotionallyFocusedPrograms.map(
                      (emotionallyFocusedProgram, index) => (
                        <TreeItem
                          onClick={() =>
                            handleMaterialItemClick(emotionallyFocusedProgram)
                          }
                          key={index}
                          className={classes.treeItem}
                          label={emotionallyFocusedProgram.description}
                          endIcon={<LinkIcon />}
                        />
                      )
                    )}
                  </TreeItem>
                )}
                {materials.treatmentPrograms.stressProgram.length > 0 && (
                  <TreeItem nodeId="5" label="Stress">
                    {materials.treatmentPrograms.stressProgram.map(
                      (stressProgram, index) => (
                        <TreeItem
                          onClick={() => handleMaterialItemClick(stressProgram)}
                          key={index}
                          className={classes.treeItem}
                          label={stressProgram.description}
                          endIcon={<LinkIcon />}
                        />
                      )
                    )}
                  </TreeItem>
                )}
                {materials.treatmentPrograms.sleepingProblemPrograms.length >
                  0 && (
                  <TreeItem nodeId="6" label="Sömnproblem">
                    {materials.treatmentPrograms.sleepingProblemPrograms.map(
                      (sleepingProblemProgram, index) => (
                        <TreeItem
                          onClick={() =>
                            handleMaterialItemClick(sleepingProblemProgram)
                          }
                          key={index}
                          className={classes.treeItem}
                          label={sleepingProblemProgram.description}
                          endIcon={<LinkIcon />}
                        />
                      )
                    )}
                  </TreeItem>
                )}
              </TreeItem>
            )}
            {materials.cbtWorksheets.length > 0 && (
              <TreeItem nodeId="7" label="KBT-arbetsblad">
                {materials.cbtWorksheets.map((cbtWorksheet, index) => (
                  <TreeItem
                    onClick={() => handleMaterialItemClick(cbtWorksheet)}
                    key={index}
                    className={classes.treeItem}
                    label={cbtWorksheet.description}
                    endIcon={<LinkIcon />}
                  />
                ))}
              </TreeItem>
            )}
            {kaddioForms?.length > 0 && (
              <TreeItem
                onClick={() => {
                  setKaddioFormularOpen(!isKaddioFormularOpen)
                }}
                nodeId="8"
                label="Kaddio-formulär"
                ref={kaddioFormularRef}
              >
                {kaddioForms.map(({ _id, name, url }) => (
                  <TreeItem
                    onClick={() => handleKaddioFormClick(_id, name, url)}
                    key={_id}
                    className={classes.treeItem}
                    label={name}
                    endIcon={<LinkIcon />}
                  />
                ))}
              </TreeItem>
            )}
          </TreeView>
        </DialogContent>
      </Popover>
      <MaterialPreviewDialog
        openDialog={openMaterialPreviewDialog}
        onClose={handleCloseMaterialPreviewDialog}
        onSend={handleSendMaterial}
        dialogItem={materialPreviewDialogItem}
      />
    </>
  )
}

export default connect(null)(MaterialPicker)
